import React, { useEffect, useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { plazaServices } from './plazaReportService';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import * as XLSX from "xlsx";
import moment from 'moment';
export const Reports = ({ all, queryMap, type, background}) => {
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(1, "days")), new Date(moment().subtract(1, "days"))]);
    const [report, setReport] = useState(null);
    const [data, setData] = useState([])
    const [plazaList, setPlazaList] = useState([]);
    const [selectedPlaza, setSelectedPlaza] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState()
    const [visible, setVisible] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const [first, setFirst] = useState(0);
    const [skip, setSkip] = useState(0);
    const [plazaCodes,setPlazaCodes]=useState([]);
    const [spvList,setSpvList]=useState([]);
    const [spv,setSpv]=useState(null);
    const toast = useRef(null);
    const thingNames = useSelector(state => state.thingNameContainer);
    let arr=thingNames.length > 0?thingNames[thingNames.length-1]: [];
    const serviceId = localStorage.getItem("serviceId");
    useEffect(()=>{
        let formattedStartDate = null;
        let formattedEndDate = null;
        let tempMonth=moment().format('MMMM')
        tempMonth=tempMonth.substring(0,3);
        for(let i=0;i<monthMap.length;i++){
            if(monthMap[i]===tempMonth)setSelectedMonth(i);
        }
        if (dateRange[0] !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedStartDate = moment(dateRange[0]).startOf('day').toDate();
        }

        if (dateRange[1] !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedEndDate = moment(dateRange[1]).endOf('day').toDate();
        }

        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);

    }, [])

    const biQuaterMonthHandler=(month)=>{
        if(type==="BiMonthly"){
            if(!selectedMonth ){
                let temp=[month];
                setSelectedMonth(temp);
            }
            else if(selectedMonth && selectedMonth.length===1){
                if(month>selectedMonth[0]+1 ||month +1<selectedMonth[0]){
                    let temp=[month];
                    setSelectedMonth(temp);
                }
                else {
                    let temp=[...selectedMonth,month];
                    setSelectedMonth(temp);
                }
            }
            else{
                let temp=[month];
                setSelectedMonth(temp);
            }

        }
        else{
            if(!selectedMonth ){
                let temp=[month];
                setSelectedMonth(temp);
            }
            else if(selectedMonth && selectedMonth.length===1){
                if(month===selectedMonth[0]+2){
                    let temp=[...selectedMonth,month-1,month];
                    setSelectedMonth(temp);
                }
                else {
                    setSelectedMonth([month]);
                }
            }
            else{
                let temp=[month];
                setSelectedMonth(temp);
            }

        }

    }

    const monthMap = [
        null,  // Placeholder for index 0, so months can be 1-indexed
        "Jan", // 1
        "Feb", // 2
        "Mar", // 3
        "Apr", // 4
        "May", // 5
        "Jun", // 6
        "Jul", // 7
        "Aug", // 8
        "Sep", // 9
        "Oct", // 10
        "Nov", // 11
        "Dec"  // 12
    ];

    const plazaFilter=["Cases where same vehicle is classified under various classes","Where return fare is deducted check whether the vehicle is returning within 24hrs","Adhoc remarks captured for exemption transactions","Cases where violation was done and tolled on any of the plaza on same day"]
    const tollSelect = (e) => {
    }

    const SPVSelector = () => {
        const customTemplate = (option) => (
            <div className="flex align-items-center">
                <i className="pi pi-map-marker" style={{color: '#0C6291' }}></i>
                <span>{option.label}</span>
            </div>
        );

        return (
            <div className="p-field mr-2 ">
                <Dropdown
                    id="spv"
                    value={spv}
                    options={spvList}
                    onChange={(e) => { setSpv(e.value);
                        if(!e.value)setSelectedPlaza(null)
                     }}
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="pi pi-map-marker" style={{ marginRight: '8px' }}></i>
                            Select a Spv
                        </span>
                    }
                    itemTemplate={customTemplate}
                    className="p-dropdown flex align-items-center bg-white"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291'
                     }}
                />
            </div>
        );
    };
    const handleDateChange = (e) => {
        const [startDate, endDate] = e.value;
        let formattedStartDate = null;
        let formattedEndDate = null;
    
        if (startDate !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedStartDate = moment(startDate).startOf('day').toDate();
        }
    
        if (endDate !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedEndDate = moment(endDate).endOf('day').toDate();
        }
    
        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
    };
    

    const onDownload = async () => {
        let Data = [];
        let data = await handleClick(queryMap[report], -1,report)
        data.map((item) => {
            let temp = {};
            for (let i = 0; i < queryMap[report].field.length; i++) {
                if (queryMap[report].field[i] && queryMap[report].head[i]) {
                    if(queryMap[report].head[i]==="Month"||queryMap[report].head[i]==="month") temp[queryMap[report].head[i]] = monthMap[item[queryMap[report].field[i]]]
                    else temp[queryMap[report].head[i]] = item[queryMap[report].field[i]]
                }
            }
            Data.push(temp);
        })
        let ws = XLSX.utils.json_to_sheet(Data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${report}.xlsx`);
    }
    //to get the list of plaza
    useEffect(() => {

        let filterPayload = {
            filter: {
            },
            projection: { "PlazaCode": 1, "PlazaName": 1 ,"zip":1,SPV:1}
        }
        if(spv!==null)filterPayload.filter["SPV"]=spv
        Promise.all([plazaServices.general(serviceId, filterPayload, "nq-plaza-master")]).then((res) => {
            let temp = [];
            let tempPlazaCodes=[];
            res[0].map((item) => {
                let tempObj;
                if(arr.length>0){
                    if(arr.includes(item.PlazaCode) ){
                        tempObj = {
                           label: `${item.PlazaName}_${item.PlazaCode}`, value: item.PlazaCode,SPV:item.SPV
                       }
                       temp.push(tempObj)
                       tempPlazaCodes.push(item.PlazaCode)
                   }
                }
                else{
                    tempObj = {
                        label: `${item.PlazaName}_${item.PlazaCode}`, value: item.PlazaCode,SPV:item.SPV
                    }
                    temp.push(tempObj)
                    tempPlazaCodes.push(item.PlazaCode)
                }
                
            })
            setPlazaCodes(tempPlazaCodes)
            if(spv===null){
                const map={};
                let spvArray=[];
                spvArray[0]={label:"ALL",value:null}
               temp.map((item)=>{
                   if(!map[item.SPV]){
                    let newTemp={
                        label:item.SPV,
                        value:item.SPV
                    }
                    map[item.SPV]=true
                    spvArray.push(newTemp);
                   }
    
                })
                setSpvList(spvArray)
                
            }
            temp = [{
                label: "All Tolls", value: null
            }, ...temp]
            setPlazaList(temp);
        })
    }, [spv])

    let downloadButton = () => {
        return (<div className="download-box mr-4">
            <Button className=" p-button-text " onClick={() => { onDownload() }}>
                <span className="download-text">Download Report</span>
                <i className="pi pi-download ml-3" style={{ color: '#0C6291' }}></i>
            </Button>
        </div>)
    }

    let filter = () => {
        return (<div className="download-box mr-4  mb-2 mt-1 ml-2">
            <Button className=" p-button-text " onClick={() => {
                setVisible(true);
            }}>
                <span className="download-text">{type==="Monthly"?"Select Month":"Select Month  Range"}</span>
                <i className="pi pi-filter ml-3" style={{ color: '#0C6291' }}></i>
            </Button>
        </div>)
    }

    let reset = () => {
        return (<div className="crate.red ml-1 mt-1">
            <Button className="bg-primary-600" onClick={() => {
                setSelectedMonth(null);
                setSelectedPlaza(null)
                setYear(2024)
            }}>
                <span className="">Reset</span>
                <i className="pi pi-filter ml-3 " ></i>
            </Button>
        </div>)
    }

    const TollSelector = () => {
        const customTemplate = (option) => {
            return (
                <div className="p-d-flex p-ai-center">
                    <i className="pi pi-map-marker" style={{ marginRight: '8px', color: '#0C6291' }}></i>
                    <span>{option.label}</span>
                </div>
            );
        };

        return (
            <div className="p-field ">
                <Dropdown
                    id="tollDropdown"
                    value={selectedPlaza}
                    options={plazaList}
                    onChange={(e) => {
                        setSelectedPlaza(e.value);
                        tollSelect(e.value);
                    }}
                    placeholder="Select a Toll"
                    itemTemplate={customTemplate}
                    className="p-dropdown p-d-flex p-ai-center bg-white"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',

                    }}
                />
            </div>
        );
    };

    const MonthSelector = () => {
        const monthList = [
            { label: "January", code: 1 },
            { label: "February", code: 2 },
            { label: "March", code: 3 },
        ];

        const monthList1 = [
            { label: "April", code: 4 },
            { label: "May", code: 5 },
            { label: "June", code: 6 },
        ];

        const monthList2 = [
            { label: "July", code: 7 },
            { label: "August", code: 8 },
            { label: "September", code: 9 },
        ];

        const monthList3 = [
            { label: "October", code: 10 },
            { label: "November", code: 11 },
            { label: "December", code: 12 }
        ];

        const incrementYear = () => setYear(year + 1);
        const decrementYear = () => setYear(year - 1);

        const onMonthClick = (month) => setSelectedMonth(month.code);

        return (
            <div>
                <Dialog visible={visible} style={{ maxWidth: '500px' }} onHide={() => {
                    setVisible(false);
                    setSelectedMonth(null);
                }}>
                    <div className='flex flex-column align-items-center justify-content-center '>
                        <div className='flex justify-content-center align-items-center mb-3'>
                            <Button className='bg-white text-black-alpha-90' style={{ borderRadius: '0.25rem' }} onClick={decrementYear}>
                                <i className="pi pi-chevron-left" style={{ fontSize: '1.5rem' }}></i>
                            </Button>
                            <p className='inter mx-3 my-0' style={{ fontSize: '1.5rem' }}>{year}</p>
                            <Button className='bg-white text-black-alpha-90' style={{ borderRadius: '0.25rem' }} onClick={incrementYear}>
                                <i className="pi pi-chevron-right" style={{ fontSize: '1.5rem' }}></i>
                            </Button>
                        </div>
                        <div className='flex flex-wrap justify-content-center mt-3 col-12'>
                            {monthList.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined ${selectedMonth && item.code === selectedMonth ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '27%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.75rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-content-between  mt-3 col-12'>
                            {monthList1.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined mr-2 ${selectedMonth && item.code === selectedMonth ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '27%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.85rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-content-center mt-3 col-12'>
                            {monthList2.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined ${selectedMonth && item.code === selectedMonth ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '28%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.85rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-content-center  mt-3 col-12'>
                            {monthList3.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined ${selectedMonth && item.code === selectedMonth ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '27%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.75rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex justify-content-between mt-6 mb-3' style={{ width: '100%' }}>
                            <Button
                                className='cancel-save bg-white text-black-alpha-90 p-button-outlined'
                                style={{ flex: 1, marginRight: '8px', borderRadius: '0.25rem', padding: '0.75rem' }}
                                onClick={() => {
                                    setSelectedMonth(null);
                                    setVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='cancel-save bg-blue-600 text-white p-button-outlined'
                                style={{ flex: 1, marginLeft: '8px', borderRadius: '0.25rem', padding: '0.75rem' }}
                                onClick={() => {
                                    if (selectedMonth || year !== new Date().getFullYear()) setVisible(false);
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    };


    const generalSelector = () => {

        const monthList = [
            { label: "January", code: 1 },
            { label: "February", code: 2 },
            { label: "March", code: 3 },
        ];

        const monthList1 = [
            { label: "April", code: 4 },
            { label: "May", code: 5 },
            { label: "June", code: 6 },
        ];

        const monthList2 = [
            { label: "July", code: 7 },
            { label: "August", code: 8 },
            { label: "September", code: 9 },
        ];

        const monthList3 = [
            { label: "October", code: 10 },
            { label: "November", code: 11 },
            { label: "December", code: 12 }
        ];

        const incrementYear = () => setYear(year + 1);
        const decrementYear = () => setYear(year - 1);

        const onMonthClick = (month) => {setSelectedMonth(month.code)
            biQuaterMonthHandler(month.code)
        };

        return (
            <div>
                <Dialog visible={visible} style={{ maxWidth: '500px' }} onHide={() => {
                    setVisible(false);
                    setSelectedMonth(null);
                }}>
                    <div className='flex flex-column align-items-center justify-content-center'>
                        <div className='flex justify-content-center align-items-center mb-3'>
                            <Button className='bg-white text-black-alpha-90' style={{ borderRadius: '0.25rem' }} onClick={decrementYear}>
                                <i className="pi pi-chevron-left" style={{ fontSize: '1.5rem' }}></i>
                            </Button>
                            <p className='inter mx-3 my-0' style={{ fontSize: '1.5rem' }}>{year}</p>
                            <Button className='bg-white text-black-alpha-90' style={{ borderRadius: '0.25rem' }} onClick={incrementYear}>
                                <i className="pi pi-chevron-right" style={{ fontSize: '1.5rem' }}></i>
                            </Button>
                        </div>
                        <div className='flex flex-wrap justify-content-center mt-3 col-12'>
                            {monthList.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined ${selectedMonth && typeof(selectedMonth)==="object"&&selectedMonth.includes(item.code) ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '27%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.75rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-content-between  mt-3 col-12'>
                            {monthList1.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined mr-2 ${selectedMonth && typeof(selectedMonth)==="object"&& selectedMonth.includes(item.code) ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '27%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.85rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-content-center mt-3 col-12'>
                            {monthList2.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined ${selectedMonth && typeof(selectedMonth)==="object"&& selectedMonth.includes(item.code) ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '28%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.85rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-content-center  mt-3 col-12'>
                            {monthList3.map((item) => (
                                <Button
                                    key={item.code}
                                    label={item.label}
                                    className={`calendar-button mt-3 p-button-outlined ${selectedMonth && typeof(selectedMonth)==="object"&& selectedMonth.includes(item.code) ? 'bg-blue-600 text-white' : 'bg-white text-black-alpha-90'
                                        }`}
                                    onClick={() => onMonthClick(item)}
                                    style={{ width: '27%', margin: '0.5rem', borderRadius: '0.25rem', padding: '0.75rem' }}
                                />
                            ))}
                        </div>
                        <div className='flex justify-content-between mt-6 mb-3' style={{ width: '100%' }}>
                            <Button
                                className='cancel-save bg-white text-black-alpha-90 p-button-outlined'
                                style={{ flex: 1, marginRight: '8px', borderRadius: '0.25rem', padding: '0.75rem' }}
                                onClick={() => {
                                    setSelectedMonth(null);
                                    setVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='cancel-save bg-blue-600 text-white p-button-outlined'
                                style={{ flex: 1, marginLeft: '8px', borderRadius: '0.25rem', padding: '0.75rem' }}
                                onClick={() => {
                                    if (selectedMonth || year !== new Date().getFullYear()) setVisible(false);
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    };

    const StyledCalendar = () => {
        return (
            <div className="p-field ml-1">
                <Calendar
                    id="range"
                    value={dateRange}
                    placeholder="Select Date"
                    onChange={handleDateChange}
                    selectionMode="range"
                    showIcon
                    readOnlyInput
                    className="p-calendar p-d-flex p-ai-center bg-white mb-2"
                    dateFormat="dd-mm-yy"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',

                    }}
                />
            </div>
        );
    };

    const handleClick = async (item, jump,heading) => {
        let filterObj = {};
        let Temp;
        if (selectedMonth && type!=="Daily") {
            Temp = selectedMonth;
            if (typeof (selectedMonth) === "number") {
                Temp = [selectedMonth];
            }
        }
        if(!plazaFilter.includes(heading)){
            if (selectedPlaza) filterObj["plazacode"] = selectedPlaza;
            
        else{
            filterObj["plazacode"]={
                $in:plazaCodes
            }
        }
        }
        if (year) filterObj["year"] = year;
        if (dateRange[1]&& type==="Daily") filterObj["date"] = {
            $gte: dateRange[0],
            $lte: dateRange[1]
        }
        if (selectedMonth) filterObj["month"] = { $in: Temp }
        if (item.payload.operation === "aggregate") {
            let count = item.payload.aggregate.length;
            item.payload.aggregate[0] = { $match: filterObj };
            for (let i = 0; i < count; i++) {
                if ("$skip" in item.payload.aggregate[i] && jump !== -1) {
                    item.payload.aggregate[i].$skip = jump
                }
                if ("$skip" in item.payload.aggregate[i] && jump === -1) {
                    item.payload.aggregate[i].$skip = 0;
                }
                if ("$limit" in item.payload.aggregate[i] && jump === -1) {
                    item.payload.aggregate[i].$limit = 1000000
                }
                if ("$limit" in item.payload.aggregate[i] && jump !== -1) {
                    item.payload.aggregate[i].$limit = 30
                }
            }

        }
        else {
            item.payload.filter = filterObj;
            if (jump !== -1) {
                item.payload["skip"] = jump;
                item.payload["limit"] = 30;
            }
            else {
                item.payload["skip"] = 0;
                item.payload["limit"] = 10000000;
            }

        }

        let Data = await plazaServices.general(serviceId, item.payload, item.collection);
        if (item.collection === "nq-adhocRemarks_monthly") {
            for (let i = 0; i < Data.length; i++) {
                if(Data[i]._col0.length>30000)Data[i]._col0 = Data[i]._col0.substring(1, 30000);
            }
        }
        if (Data.length < 29) setSkip(-1)
        if (jump !== -1) setData((prev) => { return [...prev, ...Data] });
        return Data;

    }

    const reportShow = (item, ind) => {
        if (item === report) return DetailReport();
        else {
            return <div key={ind} className="flex flex-column">
                <div className="styled-div flex flex-wrap col-12 mb-4 mt-2 ">
                    <div className='inter flex'>{item}</div>
                    <div className='viewReport flex'>{<Button className='bg-white reportsColour hover:text-gray-900' label='View reports' onClick={() => {
                        setSkip(0);
                        setFirst(0)
                        if (report) setReport(null)
                        setReport(item)
                        handleClick(queryMap[item], 0, item)
                        setData([])
                        setSkip(0)
                    }}><i className="pi pi-arrow-right ml-2 reportsColour" style={{ marginRight: '8px' }}></i></Button>}</div>

                </div>

            </div>
        }

    }
    const datatableShow = () => {
        const timeShow = (row) => {
            if (row.dt) return moment(row.dt).format('D MMM h A');
            if (row.date) return moment(row.date).format('D MMM ');
            return moment(row.transactiondt).format('D MMM h A');
        };

        const monthShow = (row) => {
            let i = parseInt(row.month);
            return monthMap[i];
        };
        if(!report)return null;

        return queryMap[report].field.map((item, index) => {
            if (item === "month") {
                return (
                    <Column
                        key={index}
                        className="bg-white font-medium p-1 bg-gray-200"
                        style={{ color: "#5E6771", backgroundColor: "#FAFAFA" }}
                        body={monthShow}
                    />
                );
            }
            if (item === "transactiondt" || item === "dt" || item === "date") {
                return (
                    <Column
                        key={index}
                        className="bg-white font-medium p-1 bg-gray-200"
                        style={{ color: "#5E6771", backgroundColor: "#FAFAFA" }}
                        body={timeShow}
                    />
                );
            } else {
                return (
                    <Column
                        key={index}
                        className="bg-white font-medium p-1 bg-gray-200"
                        style={{ color: "#5E6771", backgroundColor: "#FAFAFA" }}
                        field={item}
                    />
                );
            }
        });
    };


    const DetailReport = () => {
        const plazaHeader = report ? (
            <ColumnGroup className="bg-cyan-500 text-white card">
              <Row>
                {queryMap[report]?.head.map((item, ind) => (
                  <Column
                    header={item.toUpperCase()}
                    className={`${background} text-white`}
                    style={{ marginRight: '110px' }}
                    key={ind}
                  />
                ))}
              </Row>
            </ColumnGroup>
          ) : null;
          


        return (<div className="col-12 bg-white p-4 mt-3 radius12">
            <div className="flex justify-content-between align-items-center flex-wrap w-full">
                <div className="flex col-12 lg:col-7 inter">
                    {report}
                </div>
                <div className="flex lg:col-5 justify-content-end">
                    <div>{downloadButton()}</div>
                    <Button onClick={() => {
                        setReport(null);
                        setData([])
                        setSkip(0);
                        setFirst(0);
                    }}> Cancel</Button>
                </div>
            </div>
            <div className="col-12">
                { <DataTable headerColumnGroup={plazaHeader} className='mt-2  datatable-box card bifarcation-box col-12 ' value={data} responsiveLayout='scroll' rows={10} paginator
                    first={first} onPage={(e) => {
                        setFirst(e.first)
                        if (e.first > first && skip !== -1) {
                            let pre = skip + 30;
                            setSkip(pre);
                            handleClick(queryMap[report], pre,report)
                        };
                    }}>
                    {
                        datatableShow()
                    }
                </DataTable> 
                }
            </div>
        </div>)
    }

    return (
        <div className='flex flex-column '>
            <Toast ref={toast} />
            <div className='inter  flex flex-wrap align-items-center col-12 '>
                <div className='flex col-12 lg:col-3'><p>{type} Reports</p></div>
                <div className='flex  flex-wrap col-12 lg:col-9 lg:justify-content-end'>
                    {SPVSelector()}
                    {spv && TollSelector()}
                    {type === "Daily" && StyledCalendar()}
                    {type === "Monthly" && MonthSelector()}
                    {type === "BiMonthly" && generalSelector()}
                    {type === "Quaterly" && generalSelector()}
                    {type !== "Daily" && filter()}
                    {(selectedMonth  || selectedPlaza || year !== new Date().getFullYear()) && reset()}
                </div>
            </div>


            <div className='mt-4'>
                {
                    all.map((item, ind) => {
                        return  reportShow(item, ind)
                    })
                }
            </div>

        </div>
    )
}

