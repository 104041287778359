import React, { useState, useRef, useEffect } from 'react'
import Upload from '../../components/Upload'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { listUploadInstructions, updateUploadInstructions} from "./wmConstants"
import UploadInstruction from '../UploadInstruction'
import XLSX from "sheetjs-style"
import { Toast } from 'primereact/toast'
import { useLocation } from 'react-router-dom';
import walmartService from "./walmartService"
import { RadioButton } from "primereact/radiobutton";
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { plazaServices } from '../tollPlaza/plazaReportService'
import moment from 'moment'

const style = {
  border: { border: "1px", borderStyle: "solid", borderColor: "#e4e4e4" },
  bg: "#264653"
}
const acceptedFormat = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
const WalmartUpload = () => {
  const [file, setFile] = useState()
  const [infoMsg, setInfoMsg] = useState("")
  const [disableBtn, setDisableBtn] = useState(false)
  const [mappedKey, setMappedKey] = useState({})
  const [header, setHeader] = useState([]);
  const [uploadType, setUploadType] = useState(null)
  const [dateRange, setDateRange] = useState([]);
  const [data, setData] = useState([])
  const [template,setTemplate] = useState([])
  const workbook = useRef(null)
  const serviceId = localStorage.getItem("serviceId")
  const toast = useRef()
  const location = useLocation()
  const uploadTypeList = location?.state?.data?.uploadTypeList ?? true;
  const uid = localStorage.getItem('uid')
  
  useEffect(() => {
    const payload = {
      filter: {},
      projection: {
        timestamp: 1,
        error: 1,
        successFileName: 1,
        successCount: 1,
        reason: 1,
        fileStatus: 1,
        fileName: 1,
        rawFileBucketName: 1,
        uploadTypeList:1,      
      },
      "sort": [["timestamp", -1]]

    }
    if (dateRange.length > 0) {
      payload.filter["timestamp"] = {
        $gte: dateRange[0],
        $lte: dateRange[1]
      }

    }
    let promiseArr = [plazaServices.general(serviceId, payload, "nq-feedFileUploads")];
    if(!uploadTypeList)promiseArr.push(walmartService.getProcessTemplate(serviceId,{projection:{_id:0}}))

    Promise.all(promiseArr).then((res) => {
      setData(res[0]);
      let temp=[];
      if(res[1] && res[1].length){
        for (const key in res[1][0]){
          temp.push({
            name:key,
            value:key
          })
        }
      }
      setTemplate(temp);
    })
  }, []);
  const handleChange = (e) => {
    setMappedKey({})
    const file = e.target.files[0]
    if (!uploadTypeList && file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        workbook.current = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.current.SheetNames[0];
        const worksheet = workbook.current.Sheets[firstSheetName];
        const header = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        setHeader(header)
      };
      reader.readAsArrayBuffer(file);
    }
    setFile(file)
  }
  const handleFileUpload = () => {
    setDisableBtn(true);
    let fileObject = file;

    if (!uploadTypeList) {
      const sheetName = workbook.current.SheetNames[0];
      const worksheet = workbook.current.Sheets[sheetName];
      try {
        for (let col = 0; col <= Object.keys(header).length; col++) {
          const cellAddress = XLSX.utils.encode_cell({ c: col, r: 0 });
          const cell = worksheet[cellAddress];
          if (cell && cell.v) {
            const newHeader = mappedKey[cell.v];
            if (newHeader) {
              cell.v = newHeader;
            }
          }
        }
        const updatedWorkbook = XLSX.write(workbook.current, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([updatedWorkbook], { type: 'application/octet-stream' });
        fileObject = new File([blob], file.name, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      } catch (err) {
        console.log(err);
      }
    }

    let formData = new FormData();
    formData.append("serviceId", serviceId);
    formData.append("file", fileObject);
    formData.append("fileTypeList", uploadTypeList);
    formData.append('uid', uid);
    if (!uploadTypeList) {
      formData.append("reason", uploadType);
    }
    if (!serviceId || !fileObject || !uid) {
      console.error("Missing required fields");
      setDisableBtn(false);
      return;
    }

    walmartService.uploadFeedFile(formData)
      .then((res) => {
        setDisableBtn(false);
        setHeader([]);
        setMappedKey({});
        setUploadType(null)
        toast.current.show({ severity: 'success', summary: 'Success', detail: "File upload success" });
        setFile(null);
      })
      .catch((error) => {
        console.error("File upload failed", error);
        setDisableBtn(false);
        setDisableBtn(false);
        setUploadType(null)
        toast.current.show({ severity: 'error', summary: 'Error', detail: "File upload was not successful" });
      });
};

  const dateShow = (item) => {
    let temp = moment(item.timestamp, 'YYYY-MM-DD').format('MMMM Do YYYY');
    return <span>{temp}</span>

  }
  const showDownload = (row) => {
    if (!row.error.errorFileName) return;
    function convertS3UriToHttpsUrl() {
      const fileName=row.error.errorFileName.split("//")[1]
      const httpsUrl = `${process.env.REACT_APP_ERROR_FILE_URL}/${fileName}`;
      return httpsUrl;
    }
    const Download = () => {
      let url = convertS3UriToHttpsUrl();
      var element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", "report" + Date.now() + ".csv");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    return <>
      <Button className=" text-white " style={{ backgroundColor: `#0C6291` }} onClick={() => { Download() }} label={"Get Error File"}>
        <i className={"pi pi-download ml-2"} style={{ color: '#FFFFFF' }}></i>
      </Button>
    </>
  }
  return (
    <>
      <Toast ref={toast} />
      <UploadInstruction instruction={uploadTypeList ? listUploadInstructions : updateUploadInstructions} hasTemplate={uploadTypeList} />
      {!uploadTypeList && <div className='flex flex-wrap gap-3 card align-items-center col-12' >
        <h5 className='col-12 m-0'>Choose Your Upload Type</h5>

        <div className="flex align-items-center col-12 lg:col-3">
          <RadioButton inputId="radio1" name="radio" value="deleteListing" onChange={(e) => setUploadType(e.value)} checked={uploadType === 'deleteListing'} />
          <label htmlFor="radio1" className="ml-2">Retire Item</label>
        </div>
        <div className="flex align-items-center col-12 lg:col-3">
          <RadioButton inputId="radio1" name="radio" value="suspendListing" onChange={(e) => setUploadType(e.value)} checked={uploadType === 'suspendListing'} />
          <label htmlFor="radio1" className="ml-2">Suspend Listing</label>
        </div>
        <div className="flex align-items-center  col-12 lg:col-3">
          <RadioButton inputId="radio1" name="radio" value="update" onChange={(e) => setUploadType(e.value)} checked={uploadType === 'update'} />
          <label htmlFor="radio1" className="ml-2">Update</label>
        </div>
      </div>}
      <Upload handleUpload={handleFileUpload} accept={acceptedFormat} onchange={handleChange} fileName={file?.name} error={infoMsg}>
        {uploadTypeList && <>
          <Button className='text-xl mb-4' label="Upload" onClick={handleFileUpload} disabled={disableBtn} />
          <a className='text-xl' href={process.env.REACT_APP_IMAGE_STATIC_URL + `/${serviceId}/list_template.xlsx`} download>
            Download Template
          </a></>}
      </Upload>
      {(!uploadTypeList && file) && <div className='col-12 grid mx-auto justify-content-center'><div className='card lg:w-6 overflow-auto'><table className="w-full bg-white" style={{ borderCollapse: "collapse", clear: "right" }}>
        <thead className="bg-primary">
          <tr className="text-left">
            <th className="th bg-blue-700">FILE COLUMNS</th>
            <th className="th bg-blue-700">REFERS TO</th>
          </tr>
        </thead>
        <tbody>
          {header.map(item => (
            <tr style={style.border} key={item}>
              <td className='px-2 py-1'>{item}</td>
              <td><Dropdown value={mappedKey[item]} options={template} name={item} onChange={(e) => setMappedKey({ ...mappedKey, [item]: e.target.value })} className="bg-white w-full" optionLabel="name" placeholder="Select Key" style={{ border: '2px solid #0C6291' }} /></td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
      <div className="w-full flex justify-content-between">
        <div style={{ color: "red", float: "left" }}><h6 className="text-center">{ }</h6></div>
        <Button label="Upload" disabled={disableBtn} onClick={handleFileUpload}></Button>
      </div></div></div>}
      <div className='col-12 card'>
      <DataTable value={data} paginator={true} rows={5} responsiveLayout="scroll" >
        <Column header="Date" headerClassName="bg-blue-400 text-white white-space-nowrap" body={dateShow} ></Column>
        <Column header="File Name" headerClassName="bg-blue-400 text-white white-space-nowrap" field="fileName" ></Column>
        <Column header="File Status" headerClassName="bg-blue-400 text-white white-space-nowrap" field="fileStatus" bodyClassName=""></Column>
        <Column header="Tittle Listed" headerClassName="bg-blue-400 text-white white-space-nowrap" field="successCount"></Column>
        <Column header="Tittle Failed" headerClassName="bg-blue-400 text-white white-space-nowrap" field="error.errorCount"></Column>
        <Column header="" headerClassName="bg-blue-400 text-white white-space-nowrap" body={showDownload}></Column>
        <Column header="Reason" headerClassName="bg-blue-400 text-white white-space-nowrap" body={(row)=>{return <span>{row.uploadTypeList && row.reason}</span>}}></Column>
        <Column header="Upload Type" headerClassName="bg-blue-400 text-white white-space-nowrap" body={(row)=>{return <span>{row.uploadTypeList ?"Listing" :row.reason}</span>}}></Column>
        </DataTable>
      </div>
    </>

  )
}

export default WalmartUpload