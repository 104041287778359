import React, { Component } from "react";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { AssetService } from "../../service/AssetService";

class DgParametersTrend extends Component {
    constructor(props) {
        super(props);
        this.serviceID = localStorage.getItem("serviceId");
        this.assetService = new AssetService();
        this.state = {
            things: null,
            states: null,
            cities: null,
            dCities: null,
            zipcodes: null,
            dZipcodes: null,
            makes: null,
            models: null,
            sizes: null,
            seties: null,
            dSeties: null,
            selectedState: null,
            selectedCity: null,
            selectedZip: null,
            selectedSite: null,
            selectedMake: null,
            selectedModel: null,
            selectedSize: null,
            dateRange: null,
            DGChart: null,
            combo_one: null,
            combo_two: null,
            combo_three: null,
            dg_battery_chart: null,
            dg_RYB_PhaseKw: null,
            alternator_volt_chart: null,
            startDay: null,
            endDay: null,
        };
        this.template = this.template.bind(this);
        // this.endDay = moment().subtract(1, 'days').endOf('day').format("YYYY-MM-DD HH:mm:ss");
        // this.startDay = moment(this.today).subtract(30, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss");
    }

    componentDidMount = () => {
        const endDay = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const startDay = moment().subtract(30, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.assetService.getThings(this.serviceID).then((res) => {
            if (res.success && res.responseCode === 200) {
                let things = res.responseData.map((item) => {
                    return { name: item, code: item };
                });
                this.assetService.getAllSites(this.serviceID, res.responseData).then((res) => {
                    let states = [],
                        cities = [],
                        zipcodes = [],
                        makes = [],
                        models = [],
                        sizes = [],
                        seties = [];

                    res.forEach((item) => {
                        if (!states.some((elm) => elm.name === item.location.state)) {
                            states.push({ name: item.location.state, code: item.location.state });
                        }
                        if (!cities.some((elm) => elm.name === item.location.city)) {
                            cities.push({ name: item.location.city, code: item.location.city, state: item.location.state });
                        }
                        if (!zipcodes.some((elm) => elm.name === item.location.zip)) {
                            zipcodes.push({ name: item.location.zip, code: item.location.zip, city: item.location.city, state: item.location.state });
                        }
                        if (!makes.some((elm) => elm.name === item.make)) {
                            makes.push({ name: item.make, code: item.make });
                        }
                        if (!models.some((elm) => elm.name === item.model)) {
                            models.push({ name: item.model, code: item.model });
                        }
                        if (!sizes.some((elm) => elm.name === item.size)) {
                            sizes.push({ name: item.size, code: item.size });
                        }
                        if (!seties.some((elm) => elm.name === item.thingName)) {
                            seties.push({
                                name: item.thingName,
                                code: item.thingName,
                                state: item.location.state,
                                city: item.location.city,
                                zipcode: item.location.zip,
                                make: item.make,
                                model: item.model,
                                size: item.size,
                            });
                        }
                    });
                    let index = seties.findIndex((a) => a.name === "dg9cjldhngrmb005");
                    this.setState({ things, states, cities, dCities: cities, zipcodes, dZipcodes: zipcodes, makes, models, sizes, seties, dSeties: seties, selectedSite: seties[index], startDay, endDay, dateRange: [new Date(startDay), new Date(endDay)] }, () => {
                        this.initReport(this.state.seties[index].name, this.state.startDay, this.state.endDay);
                    });
                });
            }
        });
    };

    initReport = (thing, startDay, endDay) => {
        const payload = {
            filter: {
                thingName: thing,
                ts: { $gte: startDay, $lte: endDay },
            },
        };
        this.assetService.getParmTrendsData(this.serviceID, payload).then((res) => {
            res = res.sort((a, b) => {
                const date1 = new Date(a.ts);
                const date2 = new Date(b.ts);
                return date1 - date2;
            });

            let dgFreq = [];
            let LubeOilPressure = [],
                EngineWaterTemp = [],
                DGTotalKWH = [];
            let R_PhaLoadCurrent = [],
                Y_PhaLoadCurrentVariation = [],
                B_PhaLoadCurrentVariation = [];
            let EB_R_PhaseVolt = [],
                EB_Y_PhaseVolt = [],
                EB_B_PhaseVolt = [];
            let DGBattery = [];
            let DG_R_PhaseKW = [],
                DG_Y_PhaseKW = [],
                DG_B_PhaseKW = [],
                chart_time = [];
            let AlternatorVolt = [],
                dataLable = [];
            res.forEach((item) => {
                // console.log(moment(item.ts).valueOf());

                dataLable.push(moment(item.ts).format("MMM Do, HH:mm"));
                dgFreq.push(item.DGFrequency);
                LubeOilPressure.push(item.LubeOilPressure);
                EngineWaterTemp.push(item.EngineWaterTemp);
                DGTotalKWH.push(item.DGTotalKWH);
                R_PhaLoadCurrent.push(item["R-PhaLoadCurrent"] ? item["R-PhaLoadCurrent"] : null);
                Y_PhaLoadCurrentVariation.push(item["Y-PhaLoadCurrentVariation"] ? item["Y-PhaLoadCurrentVariation"] : null);
                B_PhaLoadCurrentVariation.push(item["B-PhaLoadCurrentVariation"] ? item["B-PhaLoadCurrentVariation"] : null);
                EB_R_PhaseVolt.push(item["EB-R-PhaseVolt"]);
                EB_Y_PhaseVolt.push(item["EB-Y-PhaseVolt"]);
                EB_B_PhaseVolt.push(item["EB-B-PhaseVolt"]);
                DGBattery.push(item.DGBattery);
                chart_time.push(moment(item.ts).format("Do MMM YY, HH:mma"));
                DG_R_PhaseKW.push(item["DG-R-PhaseKW"]);
                DG_Y_PhaseKW.push(item["DG-Y-PhaseKW"]);
                DG_B_PhaseKW.push(item["DG-B-PhaseKW"]);
                AlternatorVolt.push(item["AlternatorVolt"]);
            });
            console.log(Y_PhaLoadCurrentVariation);
            console.log(B_PhaLoadCurrentVariation);
            let DGChart = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "DG Frequency Pattern",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "DG Frequency",
                        data: dgFreq,
                        color: "#EFC000",
                    },
                ],
            };
            let combo_one = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "Variation of LubeOil presser, Engine water temperature & Total KWH over time",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "Lube Oil Pressure",
                        data: LubeOilPressure,
                        color: "#0070EF",
                    },
                    {
                        name: "Engine Water Temp",
                        data: EngineWaterTemp,
                        color: "#00AB31",
                    },
                    {
                        name: "DG Total KWH",
                        data: DGTotalKWH,
                        color: "#AB000B",
                    },
                ],
            };
            let combo_two = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "Y phase and B phase current variation",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "R-PhaLoadCurrent",
                        data: R_PhaLoadCurrent,
                        color: "#0070EF",
                    },
                    {
                        name: "Y-PhaLoadCurrentVariation",
                        data: Y_PhaLoadCurrentVariation,
                        color: "#00AB31",
                    },
                    {
                        name: "B-PhaLoadCurrentVariation",
                        data: B_PhaLoadCurrentVariation,
                        color: "#AB000B",
                    },
                ],
            };
            let combo_three = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "EB R, Y and B phase voltages",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "EB-R-PhaseVolt",
                        data: EB_R_PhaseVolt,
                        color: "#0070EF",
                    },
                    {
                        name: "EB-Y-PhaseVolt",
                        data: EB_Y_PhaseVolt,
                        color: "#00AB31",
                    },
                    {
                        name: "EB-B-PhaseVolt",
                        data: EB_B_PhaseVolt,
                        color: "#AB000B",
                    },
                ],
            };
            let dg_battery_chart = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "DG Battery",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        borderRadius: 5,
                        pointWidth: 9,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "DGBattery",
                        data: DGBattery,
                        color: "#00AB31",
                    },
                ],
            };
            let dg_RYB_PhaseKw = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                    type: "column",
                },
                title: {
                    text: "DG R, Y, B phase KW",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: chart_time,
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "DG-R-PhaseKW",
                        data: DG_R_PhaseKW,
                        color: "#0070EF",
                    },
                    {
                        name: "DG-Y-PhaseKW",
                        data: DG_Y_PhaseKW,
                        color: "#00AB31",
                    },
                    {
                        name: "DG-B-PhaseKW",
                        data: DG_B_PhaseKW,
                        color: "#AB000B",
                    },
                ],
            };
            let alternator_volt_chart = {
                chart: {
                    zoomType: "x",
                    backgroundColor: "transparent",
                },
                title: {
                    text: "Alternator Volt",
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: "<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>",
                    },
                },
                tooltip: {
                    valueDecimals: 2,
                },
                xAxis: {
                    categories: dataLable,
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                yAxis: {
                    min: 0,
                    title: false,
                    stackLabels: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            color: "#86969F",
                        },
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: [
                    {
                        name: "Alternator Volt",
                        data: AlternatorVolt,
                        color: "#0070EF",
                    },
                ],
            };
            this.setState({
                DGChart,
                combo_one,
                combo_two,
                combo_three,
                dg_battery_chart,
                dg_RYB_PhaseKw,
                alternator_volt_chart,
            });
        });
    };

    onStateChange = (value) => {
        let cities = this.state.dCities.filter((item) => item.state === value.name);
        let zips = this.state.dZipcodes.filter((item) => item.state === value.name);
        let sites = this.state.dSeties.filter((item) => item.state === value.name);
        this.setState({ selectedState: value, cities: cities, zipcodes: zips, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
        });
    };
    onCityChange = (value) => {
        let zips = this.state.dZipcodes.filter((item) => item.city === value.name);
        let sites = this.state.dSeties.filter((item) => item.city === value.name);
        this.setState({ selectedCity: value, zipcodes: zips, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
        });
    };
    onZipChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.zipcode === value.name);
        this.setState({ selectedZip: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
        });
    };
    onSiteChange = (value) => {
        this.setState({ selectedSite: value }, () => {
            this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
        });
    };
    onMakeChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.make === value.name);
        this.setState({ selectedMake: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
        });
    };
    onModelChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.model === value.name);
        this.setState({ selectedModel: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
        });
    };
    onSizeChange = (value) => {
        let sites = this.state.dSeties.filter((item) => item.size === value.name);
        this.setState({ selectedSize: value, seties: sites, selectedSite: sites[0] }, () => {
            this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
        });
    };
    onDateChange = (value) => {
        this.setState({ dateRange: value }, () => {
            if (this.state.dateRange[1] !== null) {
                let startDay = moment(this.state.dateRange[0]).format("YYYY-MM-DD HH:mm:ss");
                let endDay = moment(this.state.dateRange[1]).format("YYYY-MM-DD HH:mm:ss");
                this.setState({ startDay, endDay }, () => {
                    this.initReport(this.state.selectedSite.name, this.state.startDay, this.state.endDay);
                });
            }
        });
    };

    template(options) {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <Panel headerTemplate={this.template} toggleable collapsed="true">
                        <div className="grid">
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="State">State</label>
                                <Dropdown id="State" value={this.state.selectedState} options={this.state.states} onChange={(e) => this.onStateChange(e.value)} optionLabel="name" placeholder="Select a State" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="City">City</label>
                                <Dropdown id="City" value={this.state.selectedCity} options={this.state.cities} onChange={(e) => this.onCityChange(e.value)} optionLabel="name" placeholder="Select a City" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="zipCode">Zip Code</label>
                                <Dropdown id="zipCode" value={this.state.selectedZip} options={this.state.zipcodes} onChange={(e) => this.onZipChange(e.value)} optionLabel="name" placeholder="Select a Zip Code" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="make">Make</label>
                                <Dropdown id="make" value={this.state.selectedMake} options={this.state.makes} onChange={(e) => this.onMakeChange(e.value)} optionLabel="name" placeholder="Select a Make" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="model">Model</label>
                                <Dropdown id="model" value={this.state.selectedModel} options={this.state.models} onChange={(e) => this.onModelChange(e.value)} optionLabel="name" placeholder="Select a Model" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="size">Size</label>
                                <Dropdown id="size" value={this.state.selectedSize} options={this.state.sizes} onChange={(e) => this.onSizeChange(e.value)} optionLabel="name" placeholder="Select a Size" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="Site">Site</label>
                                <Dropdown id="Site" value={this.state.selectedSite} options={this.state.seties} onChange={(e) => this.onSiteChange(e.value)} optionLabel="name" placeholder="Select a Site" className="w-full" />
                            </div>
                            <div className="field  col-12 md:col-6 lg:col-3">
                                <label htmlFor="date">Date</label>
                                <Calendar id="range" value={this.state.dateRange} onChange={(e) => this.onDateChange(e.value)} selectionMode="range" showTime readOnlyInput className="w-full" />
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.DGChart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.DGChart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.combo_one ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.combo_one} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.combo_two ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.combo_two} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.combo_three ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.combo_three} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.dg_battery_chart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.dg_battery_chart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.alternator_volt_chart ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.alternator_volt_chart} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {this.state.dg_RYB_PhaseKw ? (
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={this.state.dg_RYB_PhaseKw} />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
}

export default DgParametersTrend;
