import React from 'react'
import { Reports } from './reports';

const biMonth=[
    "Bimonthly analysis of Vehicles not crossing Toll Plaza"
]
const queryMap={
    "Bimonthly analysis of Vehicles not crossing Toll Plaza": {
            collection: "nq-vehNotPassing_in_15days_Bimonthly",
            payload: {
                filter: {},
                projection: { _id: 0, mvc: 1, plazacode: 1, vehno: 1, date: 1,month:1 },
            },
            field: ["vehno", "mvc","month", "plazacode", "date"],
            head: ["Vehicle Number", "Vehicle Class","Month", "Plaza ", "Date"]
        },

    
};


export const BiMonthly = () => {

  return (
    <div><Reports all={biMonth} queryMap={queryMap} type={"BiMonthly"} background={"bg-green-700"} /></div>
  )
}
