import React, { Component } from "react";
// import moment from 'moment';
import moment from "moment-timezone";
import { TabView, TabPanel } from "primereact/tabview";
import DetailsComponent from "./Components/DetailsComponent";
import DefeactsComponent from "./Components/DefeactsComponent";
import TenancyComponent from "./Components/TenancyComponent";
import ReportComponent from "./Components/reportComponent";
import MyxComponent from "./Components/myxComponent";
import { TowerService } from "../../service/TowerService";
import EquipmentComponent from "./Components/EquipmentComponent";
import CustomReportComponent from "./Components/customReportComponent";
import MyxRevitComponent from "./Components/myxRevitComponent";
import AntennaList from "./Components/AntennaList";
class SiteComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            site: null,
            weather: null,
            historicalWeather: null,
            weatherEvent: [],
            weatherHistory: [],
        };

        this.towerService = new TowerService();
    }

    componentDidMount() {
        //const { id } = this.props.match.params;
        // console.log(moment.tz.zonesForCountry('US'))
        let currentState = {
            site: this.props.data,
            currentTime: moment.now(),
        };
        console.log(this.props.data)
        let position = {
            lat: this.props.data.siteDetails.location.latitude,
            lng: this.props.data.siteDetails.location.longitude,
        };

        let myxTowerid = this.props.data.myxTowerid;
        let to_data = moment().format("YYYY-MM-DD");
        let from_date = moment().subtract(1, "years").format("YYYY-MM-DD");

        Promise.all([this.towerService.getCurrentWeather(position), this.towerService.getHistoricalWeather(myxTowerid), this.towerService.getWeatherEvents(myxTowerid), this.towerService.getWeatherGraphData(myxTowerid, from_date, to_data)])
            .then((response) => {
                currentState.weather = response[0];
                currentState.historicalWeather = response[1][0];
                let weatherEvent = response[2];
                let weatherHistory = response[3];
                weatherHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

                let weatherEventArray = [];
                if (weatherEvent.length > 0) {
                    weatherEvent.forEach((item, index) => {
                        weatherEventArray.push({
                            index: index + 1,
                            name: item._id.condition,
                            date: item.dates[0],
                        });
                    });
                }

                currentState.weatherEvent = weatherEventArray;
                currentState.weatherHistory = weatherHistory;
                this.setState(currentState);
            })
            .catch();
    }

    render = () =>
        this.state.site === null && this.state.weather === null ? null : (
            <TabView scrollable>
                <TabPanel header="Site Details">
                    <DetailsComponent tower={this.state.site} currentWeather={this.state.weather} historicalWeather={this.state.historicalWeather} weatherEvent={this.state.weatherEvent} currentTime={this.state.currentTime} weatherHistory={this.state.weatherHistory} />
                </TabPanel>
                <TabPanel header="Defects">
                    <DefeactsComponent tower={this.state.site} />
                </TabPanel>
                <TabPanel header="Tenancy">
                    <TenancyComponent tower={this.state.site} />
                </TabPanel>
                {this.state.site.report ? (
                    <TabPanel header="Site Report">
                        <ReportComponent site={this.state.site} />
                    </TabPanel>
                ) : null}
                {this.state.site.myxlink ? (
                    <TabPanel header="Digital Twin">
                        <MyxComponent site={this.state.site} />
                    </TabPanel>
                ) : null}
                {this.state.site.reviturl ? (
                    <TabPanel header="BIM Model">
                        <MyxRevitComponent site={this.state.site} />
                    </TabPanel>
                ) : null}
                {this.state.site.customReport ? (
                    <TabPanel header="Custom Report">
                        <CustomReportComponent site={this.state.site} />
                    </TabPanel>
                ) : null}
                <TabPanel header="Site Equipment">
                    <EquipmentComponent tower={this.state.site} />
                </TabPanel>
                {/* <TabPanel header="Antenna List">
                    <AntennaList tower={this.state.site} type="leg" />
                </TabPanel> */}
                <TabPanel header="Antennas">
                    <AntennaList tower={this.state.site} type="tenent" />
                </TabPanel>
            </TabView>
        );
}

export default SiteComponent;
