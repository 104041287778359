export const mapKey = "WxDouUltFmSyBeS9FpXLZYB3GeLkKid7w_UYEnmlF2c";
export const weatherApiKey = "db5175c62511584caecb67ad6d034d40";

export const colorPalettes = {
    bar_x_axis_text: "black",
    color1: "#264653",
    color1_1: "#111f25",
    color2: "#2A9D8F",
    color2_1: "#0da2919e",
    color2_2: "#bff5ee",
    color3: "#E9C46A",
    color3_1: "#f6dfa7",
    color4: "#F4A261",
    color5: "#E76F51",
    color5_1: "#f9a793",
};

export const serviceTypeName = {
    VEHICLE_OBD: "Vehicle Tracking & OBD",
};

export const chartHeadingStyle = {
    fontSize: "18px",
    fontWeight: "700",
    textTransform: "capitalize",
};

export const barChartColors = [colorPalettes.color2, colorPalettes.color4, colorPalettes.color3, colorPalettes.color5, colorPalettes.color1];
export const pieChartColors = [colorPalettes.color1, colorPalettes.color5, colorPalettes.color2, colorPalettes.color4, colorPalettes.color3, colorPalettes.color1_1, colorPalettes.color2_1, colorPalettes.color5_1, colorPalettes.color2_2, colorPalettes.color3_1];

export const piechartOptions = {
    plugins: {
        legend: {
            display: true,
            labels: {
                font: {
                    size: 14,
                },
                usePointStyle: true,
                pointStyle: "circle",
                // color: "#495057",
                fontSize: "20px",
            },
        },
    },
};

const textColor = getComputedStyle(document.body).getPropertyValue("--text-color") || "rgba(0, 0, 0, 0.87)";
const gridLinesColor = getComputedStyle(document.body).getPropertyValue("--divider-color") || "rgba(160, 167, 181, .3)";
const fontFamily = getComputedStyle(document.body).getPropertyValue("--font-family");

export const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
        legend: {
            labels: {
                font: { size: 14 },
                fontFamily,
                color: textColor,
            },
        },
    },
    scales: {
        x: {
            ticks: {
                font: {
                    size: 14,
                },
                color: "black",
            },
            grid: {
                color: "white",
            },
        },
        y: {
            ticks: {
                fontFamily,
                color: textColor,
            },
            grid: {
                color: gridLinesColor,
            },
            title: {
                text: "Fuel cost in Rs",
            },
        },
    },
    barThickness: 20,
};

export const userTypeDropdown = [
    { type: "Publisher", value: ["Publisher_READ", "Publisher_WRITE"] },
    { type: "Artwork", value: ["Artwork_READ", "Artwork_WRITE"] },
    { type: "Printer", value: ["Printer_WRITE"] },
    { type: "Admin", value: ["Admin_READ", "Admin_WRITE"] },
];

export const userFSAccess = ["Publisher_READ", "Publisher_WRITE", "Artwork_READ", "Artwork_WRITE", "Printer_WRITE", "Admin_READ", "Admin_WRITE"];

export const fileEventStatus = [
    { name: "New content uploaded", code: "New content uploaded", color: "#6366F1" },
    { name: "1st draft approved/feedback", code: "1st draft approved/feedback", color: "#3B82F6" },
    { name: "2nd draft approved/feedback", code: "2nd draft approved/feedback", color: "#F59E0B" },
    { name: "Final version approved/feedback", code: "Final version approved/feedback", color: "#e90bf5" },
    { name: "1st draft uploaded for review", code: "1st draft uploaded for review", color: "#EF4444" },
    { name: "2nd draft uploaded for review", code: "2nd draft uploaded for review", color: "#7552f2" },
    { name: "Final version uploaded", code: "Final version uploaded", color: "#689F38" },
];

export const tollUserType = [
    { type: "Super Admin", value: "Super Admin" },
    { type: "Revenue Manager", value: "Revenue Manager" },
    { type: "Manager", value: "Manager" },
    { type: "Users", value: "Users" },
];