import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { plazaServices } from './plazaReportService'
import { Calendar } from 'primereact/calendar'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { colorPalettes } from '../../utilities/constant'
import moment from 'moment'

export const FilesRecivedInfo = () => {
    const serviceId = localStorage.getItem("serviceId");
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(1, "days").format('YYYY-MM-DD')), new Date(moment().subtract(1, "days").format('YYYY-MM-DD'))]);
    const [data, setData] = useState([])
    const fieldscheck = ["vrn", "cch", "pos", "hhm", "overload"];
    useEffect(() => {
        let formattedStartDate = null;
        let formattedEndDate = null;
        if (dateRange[0] !== null) {
            formattedStartDate = moment(dateRange[0]).startOf('day').toDate();
        }
        if (dateRange[1] !== null) {
            formattedEndDate = moment(dateRange[1]).endOf('day').toDate();
        }
        const temp = [formattedStartDate, formattedEndDate];

        const payload = {
            filter: {
                date: {
                    $gte: temp[0],
                    $lte: temp[1]
                }
            },
        }
        if (dateRange[1]) {
            Promise.all([plazaServices.general(serviceId, payload, "nq-file-status")]).then((res) => {
                setData(res[0])
            })
        }
    }, [dateRange[1]])

    const handleDateChange = (e) => {
        const [startDate, endDate] = e.value;
        let formattedStartDate = null;
        let formattedEndDate = null;

        if (startDate !== null) {
            formattedStartDate = moment(startDate).startOf('day').toDate();
        }

        if (endDate !== null) {
            formattedEndDate = moment(endDate).endOf('day').toDate();
        }

        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
    };

    const dateFormatter = (row) => {
        return moment(row.date).format("MMM Do ")
    }

    const StyledCalendar = () => {
        return (
            <div className="p-field w-full">
                <label className='text-base p-1'>Select Date Range</label>
                <Calendar
                    id="range"
                    value={dateRange}
                    placeholder="Select Date"
                    onChange={handleDateChange}
                    selectionMode="range"
                    showIcon
                    readOnlyInput
                    className="p-calendar flex align-items-center bg-white"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',
                        marginTop: "0.5rem"
                    }}
                    dateFormat="dd-mm-yy"
                />
            </div>
        );
    };
    const plazaHeader = (
        <ColumnGroup className="bg-cyan-500 text-white col-12">
            <Row>
                <Column header={"Date"} className="bg-cyan-500 pl-1 text-white"></Column>
                <Column header={"Plaza"} className="bg-cyan-500 pl-1 text-white"></Column>
                <Column header={"VRN"} className="bg-cyan-500 pl-1 text-white"></Column>
                <Column header={"CCH"} className="bg-cyan-500 pl-1 text-white"></Column>
                <Column header={"POS"} className="bg-cyan-500 pl-1 text-white"></Column>
                <Column header={"HHM"} className="bg-cyan-500 pl-1 text-white"></Column>
                <Column header={"OVERLOAD"} className="bg-cyan-500 pl-1 text-white"></Column>
            </Row>
        </ColumnGroup>
    );
    const receivedStatus = (row, item) => {
        console.log(row, item)
        return (item in row) ? (
            <span className='border-circle ' style={{ backgroundColor: row[item] ? "#689f38" : colorPalettes.color5, display: "inline-block", width: "1.2rem", height: "1.2rem" }}></span>
        ) : (
            <span className='bg-white text-black w-full h-full'>N/A</span>
        )
    }
    return (
        <>
            <div className='col-12 md:col-6 lg:col-3'>{StyledCalendar()}</div>
            <div className='col-12 card mt-4 '>
            <h5 className='mb-0'>File Upload Status</h5>
                <DataTable className='mt-2 text-center' headerColumnGroup={plazaHeader} value={data} responsiveLayout="scroll" rows={14} paginator>
                    <Column className="bg-white font-medium p-1 bg-gray-200 vertical-align-middle" body={dateFormatter}></Column>
                    <Column className="bg-white font-medium p-1 bg-gray-200 vertical-align-middle" field="plazaCode"></Column>
                    {fieldscheck.map((item) => {
                        return <Column className="bg-white font-medium p-1 my-auto vertical-align-middle" key={item} body={(row) => receivedStatus(row, item)} />;
                    })}
                </DataTable>
            </div>
        </>
    )
}
