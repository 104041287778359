import React, { useEffect, useState } from 'react'
import { plazaServices } from '../tollPlaza/plazaReportService';
import HighchartsReact from "highcharts-react-official";
import { useHistory } from 'react-router-dom';
import Highcharts from "highcharts";

export const Dashboard = () => {
    const [data, setData] = useState([])
    const [orderbyStatus, setOrderByStatus] = useState([])
    const [line, setLine] = useState(null);
    const [totalAmount, setAmount] = useState(0);
    const [totalorder, setTotalOrder] = useState(0);
    const [totalTransit, setTotaLTransit] = useState(0);
    const [totalfulfiled, setTotalFulfiled] = useState(0);
    const [reproOrder, setSentToRepro] = useState(0)
    const [canceledOrder, setSentCanceledOrder] = useState(0)
    const history = useHistory();
    const serviceId = localStorage.getItem("serviceId");
    const showCharts = (charts, col, card) => {
        return <div className='col-12'>
            <HighchartsReact highcharts={Highcharts} options={charts} />
        </div>
    }

    const pieChartType = (res, field, route) => {
        let siteCount = [];
        let checkData = 0;
        res.map((item) => {
            if (item.count === null) item.count = 0
            let data = {
                category: item.status,
                name: item.status,
                y: item.count
            }
            if (item.count > 0) checkData = 1;
            siteCount.push(data);
        })
        if (!checkData) siteCount = [];
        let slabs = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: `${field}`,
                style: {
                    fontSize: "21px",
                    fontWeight: "700",
                    fontFamily: "Roboto"
                },
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}",
                        style: {
                            fontSize: "11px",
                        },
                    },
                    point: {
                        events: {
                            click: function (e) {
                                history.push(`${route}`);
                            }
                        }
                    },
                    showInLegend: false,
                },
            },
            series: [
                {
                    name: "",
                    colorByPoint: true,
                    data: siteCount
                },
            ],
        };
        return slabs;
    }

    const LineChart = (names, count) => {
        let seriesData = [];
        seriesData.push({
            name: "",
            data: count
        })

        const lineChart = {
            chart: {
                type: 'spline'
            },
            title: {
                text: 'Order SLA Violation',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: names,
                accessibility: {
                    rangeDescription: ''
                }
            },
            yAxis: {
                title: {
                    text: 'Number of Days'
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },

                }
            },
            series: seriesData,
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };

        return lineChart;
    };

    const showMessage = (Message) => {
        return <div className="my-info-message borderStyle2 " style={{borderRadius:"4px"}}>{Message}</div>;
    }

    const yesterdayCard = (field, borderStyle, dailyTotal, mt) => {
        return (
            <div className={`my-class shadow-3 ${borderStyle} col-12 ${mt}`}>
                <div className='flex-colum border-color'>
                    <div className='flex align-items-center justify-content-between flex-wrap p-1'>
                        <div className='p-flex p-flex-column p-text-left p-text-dark ' style={{ fontFamily: 'Inter, sans-serif', fontSize: '1.3rem', fontWeight: '600', lineHeight: '1.25rem', letterSpacing: '1px' }}>
                            {field}
                        </div>
                    </div>
                    <div className='text-2xl font-bold line-height-4 mt-1 ml-3 mb-2'>
                        {field === "Total Order Value" ? `$ ${dailyTotal}` : `${dailyTotal}`}
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$walmart.listingStatus",
                        count: { $sum: 1 },

                    }
                },
                {
                    $project: {
                        _id: 0,
                        status: "$_id",
                        count: 1
                    }
                },
            ]
        }
        const orderStatusPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$lastStatus",
                        count: { $sum: 1 },
                        total: { $sum: `$charge.amount` },
                        totalType: { $sum: `$qty` }

                    }
                },
                {
                    $project: {
                        _id: 0,
                        status: "$_id",
                        count: 1,
                        total: 1,
                        totalType: 1
                    }
                },
            ]
        }

        const lineChartPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        deliveryDate: { $exists: true }
                    }
                },
                {
                    $group: {
                        _id: "$sku",
                        deliveryDate: { "$first": "$indiaShipment.delivery" },
                        expectedDeliveryDate: { "$first": "$indiaShipment.expectedDelivery" }

                    }
                },
                {
                    $project: {
                        _id: 0,
                        sku: "$_id",
                        deliveryDate: 1,
                        expectedDeliveryDate: 1
                    }
                },
            ]

        }

        Promise.all([plazaServices.general(serviceId, payload, "nq-processing-status"), plazaServices.general(serviceId, orderStatusPayload, "nq-order-items"), plazaServices.general(serviceId, lineChartPayload, "nq-order-items")]).then((res) => {
            let data = pieChartType(res[0], "Books Listing Status", "/walmart-tittle")
            setData(data);
            data = pieChartType(res[1], "Orders Items By Status", "/wm-orders")
            setOrderByStatus(data);
            let sum = 0;
            let countTotal=0;
            res[1].map((item) => {
                if (item.status === "ShippedToUS" || item.status === "Shipped"){
                    setTotaLTransit(item.count);
                    sum += item.total;
                    countTotal+=item.count
                }
                if (item.status === "fulfiled"){
                    setTotalFulfiled(item.count)
                    sum += item.total;
                    countTotal+=item.count
                } 
                if (item.status === "SentToRepro"){
                    setSentToRepro(item.count)
                    sum += item.total;
                    countTotal+=item.count
                }
                if (item.status === "Cancelled"){
                    setSentCanceledOrder(item.count)
                    countTotal+=item.count
                }
            })
            setTotalOrder(countTotal);
            setAmount(sum);
            if (res[2].length) {
                let lateData = [];
                let sku = [];
                res[2].map((item) => {
                    const date1 = new Date(item.deliveryDate);
                    const date2 = new Date(item.expectedDeliveryDate);
                    const differenceInMilliseconds = date2 - date1;
                    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
                    sku.push(item.sku);
                    lateData.push(differenceInDays)
                })
                const lineChartConfig = LineChart(sku, lateData);
                setLine(lineChartConfig);
            }
        })
    }, [])

    return (
        <div className='grid col-12 justify-content-center ml-1'>
            <div className='grid nested-grid col-12'>
                <div className=' card col-12 lg:col-6 flex justify-content-center mt-1 align-items-center col-12  h-30rem borderStyle2 mt-3'>
                    {showCharts(data, "", "")}
                </div>
                <div className=' col-12 lg:col-6 flex flex-wrap'>
                    <div className='flex col-6   lg:col-6 h-10rem '>{yesterdayCard("Total Order Items", "borderStyle1", totalorder)}</div>
                    <div className='flex  col-6  lg:col-6  h-10rem '>{yesterdayCard("Orders With Repro", "borderStyle2", reproOrder)}</div>
                    <div className='flex  col-6  lg:col-6  h-10rem '>{yesterdayCard("Total Cancelled Items", "borderStyle3", canceledOrder, "mt-1")}</div>
                    <div className='flex col-6   lg:col-6  h-10rem '>{yesterdayCard("Total Order Value", "borderStyle1", totalAmount, "mt-1")}</div>
                    <div className='flex col-6   lg:col-6 h-10rem '>{yesterdayCard("Order Item in Transit", "borderStyle2", totalTransit, "mt-2")}</div>
                    <div className='flex  col-6  lg:col-6 h-10rem '>{yesterdayCard("Order Item fulfilled", "borderStyle3", totalfulfiled, "mt-2")}</div>
                </div>
            </div>
            <div className='grid col-12 gap-1 justify-content-between lg:flex-wrap'>
                <div className='col-12 card borderStyle2 lg:col-6 flex justify-content-center mt-2'>
                    {showCharts(orderbyStatus, "", "")}
                </div>
                <div className='col-12  lg:col-6 '>{line ? showCharts(line) : <>
                    {showMessage("No Orders are Delivered")}
                </>}
                </div>
            </div>
        </div>
    )
}
