import React from "react";
import { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import service from "./WeatherDataService";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from "primereact/row";
import { Dialog } from "primereact/dialog";
import { ColumnGroup } from "primereact/columngroup";
import { ProgressSpinner } from 'primereact/progressspinner';
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import { convertValue } from "../../utilities/unitConversion";
import { useSelector } from "react-redux";

const WeatherDataDashBooard = () => {
    let serviceId = localStorage.getItem("serviceId");
    const [slabs, setSlabs] = useState(null);
    const [first, setFirst] = useState(0);
    const [generalslabs, setGeneralSlabs] = useState(null);
    const [rainfall, setRainfall] = useState(null);
    const [details, setDetails] = useState([]);
    const [windspeed, setWindSpeed] = useState(null);
    const [snow, setSnow] = useState(null);
    const [snowgreat100, setSnowgreat100] = useState(null);
    const [tempg40, setTempg40] = useState(null);
    const [templ0, setTempl0] = useState(null);
    const [modal, showmodal] = useState(false);
    const [field, setField] = useState(null);
    const [ee, setE] = useState(null);
    const [skip, setSkip] = useState(0);
    const unit = useSelector(state => state.changeUnit)
    
    const showCharts=(charts)=>{
        return <HighchartsReact highcharts={Highcharts} options={charts} />
    }
    
    const showMessage = (Message) => {
        return <div className="my-info-message">{Message}</div>;
    }

    async function chartClick(e, fi, temp) {
        let detail;
        let tempinFarenHeight=["temperature_difference","max_maxtemp_c","min_mintemp_c"];
        let mmToInch=["sum_totalprecip_mm","max_totalprecip_mm", "sum_totalsnow_mm","max_totalsnow_cm"];
        let kmptomph=["max_maxwind_kph"];
        if (fi === "temperature_difference" || fi === "max_maxwind_kph") {
            detail = await service.statebins(serviceId, e.point.category, fi, temp);
        }
        else {
            let ranges = e.point.category.split("-");
            detail = await service.siteDetails(parseInt(ranges[0]), parseInt(ranges[1]), fi, serviceId, temp);

        }
        if(detail.length>0 && unit==="FPS"){
            detail.map((item)=>{
                for(const key in item){
                    if( tempinFarenHeight.includes(key))item[key]=convertValue(item[key],"temperature", unit,  null, false)
                    else if(mmToInch.includes(key))item[key]=convertValue(item[key],"precipitation", unit, null, false)
                    else if(kmptomph.includes(key))item[key]=convertValue(item[key],"speed", unit, null,  null, false)
                }
            })

        }
        if (detail.length === 0 || detail.length < 30) setSkip(-1);
        setDetails((prev) => { return [...prev, ...detail] });
        showmodal(true);
    }
    const getfield = {
        "temperature_difference": ["temperature_difference", "max_maxtemp_c", "min_mintemp_c"],
        "max_maxwind_kph": ["max_maxwind_kph"],
        "sum_of_gt_40c_temp_days": ["sum_of_gt_40c_temp_days", "max_maxtemp_c"],
        "sum_of_lt_0c_temp_days": ["sum_of_lt_0c_temp_days", "min_mintemp_c"],
        "sum_of_gt_50kph_wind_days": ["sum_of_gt_50kph_wind_days", "max_maxwind_kph"],
        "sum_totalsnow_mm": ["sum_totalsnow_mm", "max_totalsnow_cm"],
        "sum_of_gt_100mm_snow_days": ["sum_of_gt_100mm_snow_days", "max_totalsnow_cm"],
        "avg_avghumidity": ["avg_avghumidity", "max_avghumidity", "min_avghumidity"],
        "sum_totalprecip_mm": ["sum_totalprecip_mm", "max_totalprecip_mm"],
        "sum_of_gt_50mm_rain_days": ["sum_of_gt_50mm_rain_days", "max_totalprecip_mm"]

    }
    const getHeading = {
        "temperature_difference": `Temperature diffrence in ${unit==="FPS"?"°F":"°C"}`,
        "max_maxtemp_c": `Maximum Temperature in ${unit==="FPS"?"°F":"°C"}`,
        "min_mintemp_c": `Minimum Temperature in ${unit==="FPS"?"°F":"°C"}`,
        "avg_maxwind_kph": `Average Maximum Wind Speed ${unit==="FPS"?"(MPH)":"(KPH)"}`,
        "max_maxwind_kph": `Maximum Wind Speed ${unit==="FPS"?"(MPH)":"(KPH)"}`,
        "sum_of_gt_40c_temp_days": `Days with Temperature Above ${unit==="FPS"?"104 °F":"40 °C"}`,
        "sum_of_lt_0c_temp_days": `Days with Temperature Below ${unit==="FPS"?"32 °F":"0 °C"}`,
        "sum_of_gt_50kph_wind_days": `Days with Wind Speed Above ${unit==="FPS"?"19.6 MPH":"30 KPH"}`,
        "sum_of_gt_100mm_snow_days": `Days with Snowfall Exceeding ${unit==="FPS"?"4 inches":"100 mm"}`,
        "sum_of_gt_50mm_rain_days": `Days with Rainfall Exceeding ${unit==="FPS"?"2 inches":"50 mm"}`,
        "sum_totalsnow_mm": `Total Snowfall ${unit==="FPS"?"inches":"mm"}`,
        "max_totalsnow_cm": `Maximum Total Snowfall ${unit==="FPS"?"inches":"mm"}`,
        "sum_totalprecip_mm": `Total Rainfall ${unit==="FPS"?"inches":"mm"}`,
        "max_totalprecip_mm": `Maximum Rainfall for a day ${unit==="FPS"?"inches":"mm"}`

    }

    const barCharType = (res, field, dataKey, units, colour) => {
        let slabs = [];
        let empCount = [];
        res.map((item) => {
            slabs.push(item._id + units);
            if(item.count!==null)empCount.push(item.count);
        })
        const slabwiseCount = {
            chart: {
                type: "column",
            },
            title: {
                text: ` ${field}`,
                align: "center",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: slabs,
                title: {
                    text: field,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "Number of Sites",
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: "Number of sites",
                    data: empCount,
                    color: colour,
                    events: {
                        click: (e) => {
                            setE(e);
                            setField(dataKey)
                            chartClick(e, dataKey, 0)
                        }
                    },
                }

            ],
        };
        return slabwiseCount;


    }
    const stateWise = (res, field, dataKey, units, colour) => {
        let slabs = [];
        let empCount = [];
        res.map((item) => {

            slabs.push(item._id);
            empCount.push(item.count);
        })
        const slabwiseCount = {
            chart: {
                type: "column",
            },
            title: {
                text: ` ${field}`,
                align: "center",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: slabs,
                title: {
                    text: field,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "Number of Sites",
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: "Number of sites",
                    data: empCount,
                    color: colour,
                    events: {
                        click: (e) => {
                            setE(e);
                            setField(dataKey)
                            chartClick(e, dataKey, 0)
                        }
                    },
                }

            ],
        };
        return slabwiseCount;


    }

    const pieChartType = (res, field, dataKey, units) => {
        let siteCount = [];
        let checkData = 0;
        res.map((item) => {
            if (item.count === null) item.count = 0
            let data = {
                category: item._id + +units,
                name: item._id + units + " :" + item.count + " sites",
                y: item.count
            }
            if(units.includes("mm") &&unit==="FPS"){
                let fpsid=item._id.split("-");
                fpsid[0]=Math.round(parseFloat(convertValue(fpsid[0],"precipitation", unit, null, false)))
                fpsid[1]=Math.round(parseFloat(convertValue(fpsid[1],"precipitation", unit, null, false)))
                item._id= fpsid[0]+"-"+ fpsid[1]
                data.name=item._id + " inches" + " :" + item.count + " sites";
            }
            if (item.count > 0) checkData = 1;

            siteCount.push(data);
        })
        if (!checkData) siteCount = [];
        const slabs = {
            chart: {
                type: "pie",
            },
            title: {
                text: `${field}`,
                align: "center",
                style: chartHeadingStyle,
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true
                    },
                    showInLegend: true,
                    point: {
                        events: {
                            click: function (e) {
                                setE(e);
                                setField(dataKey)
                                chartClick(e, dataKey, 0)
                            }
                        }
                    }
                }
            },
            series: [{
                name: "Number of sites",
                data: siteCount,
                color: colorPalettes.color2,
            }],
        };
        return slabs;

    }

    useEffect(() => {
        if (first > 0) setFirst(0);
        let init = async () => {
            let minMaxMap;
            await Promise.all([service.getMinimun(serviceId)]).then((res) => {
                minMaxMap = res[0][0];
            })
            await Promise.all([service.stateWise(serviceId, "temperature_difference")]).then((res) => {
                const ans1 = stateWise(res[0], "", "temperature_difference", "  &#8451", colorPalettes.color1);
                setSlabs(ans1);
            })
            await Promise.all([service.generalSlab(serviceId, "sum_of_gt_40c_temp_days", minMaxMap), service.generalSlab(serviceId, "sum_of_lt_0c_temp_days", minMaxMap)]).then((res) => {
                const ans1 = barCharType(res[0], " ", "sum_of_gt_40c_temp_days", " ", colorPalettes.color3);
                setTempg40(ans1);
                const ans2 = barCharType(res[1], "", "sum_of_lt_0c_temp_days", " ", colorPalettes.color3);
                setTempl0(ans2)
            })
            Promise.all([service.generalSlab(serviceId, "sum_totalsnow_mm", minMaxMap), service.generalSlab(serviceId, "sum_of_gt_100mm_snow_days", minMaxMap), service.generalSlab(serviceId, "sum_of_gt_50mm_rain_days", minMaxMap), service.generalSlab(serviceId, "sum_totalprecip_mm", minMaxMap)]).then((res) => {
                const ans1 = pieChartType(res[0], "", "sum_totalsnow_mm", " mm")
                setSnow(ans1);
                const ans2 = pieChartType(res[1], "", "sum_of_gt_100mm_snow_days", " days");
                setSnowgreat100(ans2);
                const ans3 = pieChartType(res[2], "", "sum_of_gt_50mm_rain_days", " days");
                setGeneralSlabs(ans3);
                const ans4 = pieChartType(res[3], "", "sum_totalprecip_mm", " mm");
                setRainfall(ans4);

            })
            Promise.all([service.stateWise(serviceId, "max_maxwind_kph")]).then((res) => {
                const ans2 = stateWise(res[0], " ", "max_maxwind_kph", " days", colorPalettes.color5);
                setWindSpeed(ans2);
            })
        }
        init();
    }, [])

    const tableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Site Id" className="bg-primary"></Column>
                {field && getfield[field].map((item, ind) => {
                    return (
                        <Column header={getHeading[item]} key={ind} className="bg-primary"></Column>
                    )
                })}
                <Column header="City" className="bg-primary"></Column>
                <Column header="State" className="bg-primary"></Column>
            </Row>
        </ColumnGroup>
    );
    const getDialog = () => {
        return (
            <Dialog header={"Site Details"} visible={modal} style={{ width: '80vw' }} onHide={() => {
                showmodal(false);
                setDetails([]);
                setSkip(0);
                setFirst(0);
            }
            } >
                <div className="card">
                    <DataTable value={details} dataKey="" paginator rows={15} first={first} onPage={(e) => {
                        setFirst(e.first)
                        if (e.first > first && skip !== -1) {
                            let temp = skip + 30;
                            setSkip((prev) => prev + 30);
                            chartClick(ee, field, temp)
                        };
                    }} visible={modal} responsiveLayout="scroll" headerColumnGroup={tableHeader}   >
                        <Column field="siteid" className="bg-teal-500 text-white" header="siteid"></Column>
                        {field && getfield[field].map((item, ind) => {
                            return (
                                <Column field={item} key={ind} className="bg-teal-500 text-white" ></Column>
                            )
                        })}
                        <Column field="ct" className="bg-teal-500 text-white" ></Column>
                        <Column field="st" className="bg-teal-500 text-white" ></Column>
                    </DataTable>
                </div>

            </Dialog>
        )
    }
    return (
        <div className="grid">
            <div className="flex col-12">
                <div className="card col-12 mr-3 h-full">
                    <div className="flex align-items-center justify-content-center ">
                        <h4 className="header">{`Sites Experiencing Large Temperature ${convertValue(35, "temperature", unit, null, false)}  Variations`} </h4>
                    </div>
                    {slabs === null ? <div className="flex align-items-center justify-content-center"><ProgressSpinner /></div> : slabs && slabs.series && slabs.series.length > 0 && slabs.series[0].data && slabs.series[0].data.length > 0 ? showCharts(slabs) : showMessage("No sites are available")}
                </div>
                {getDialog()}
            </div>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <div className="flex align-items-center justify-content-center ">
                        <h4 className="header">{`Number of Days sites Experiencing Temperature > ${convertValue(40, "temperature", unit, null, false)} `} </h4>
                    </div>
                    {tempg40 === null ? <div className="flex align-items-center justify-content-center"><ProgressSpinner /></div> : tempg40 && tempg40.series && tempg40.series.length > 0 && tempg40.series[0].data && tempg40.series[0].data.length > 0 ? showCharts(tempg40) : showMessage("No sites are available")}
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <div className="flex align-items-center justify-content-center">
                        <h4 className="header">{`Number of Days sites Experiencing Temperature < ${convertValue(0,"temperature", unit, null, false)}`}</h4>
                    </div>
                    {templ0 === null?<div className="flex align-items-center justify-content-center"><ProgressSpinner/></div>:templ0 && templ0.series && templ0.series.length>0 && templ0.series[0].data && templ0.series[0].data.length > 0 ?showCharts(templ0): showMessage("No sites are available")}
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <div className="flex align-items-center justify-content-center">
                        <h4 className="header">{`Cumulative Rainfall >${convertValue(1200, "precipitation", unit, null, false)} for (Last 6 Months)`} </h4>
                    </div>
                    {rainfall===null ?<div className="flex align-items-center justify-content-center "><ProgressSpinner/></div>:rainfall && rainfall.series && rainfall.series.length>0 && rainfall.series[0].data && rainfall.series[0].data.length > 0 ?showCharts(rainfall) : showMessage("No sites are available")}
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <div className="flex align-items-center justify-content-center">
                        <h4 className="header">{`Number of Days sites Experiencing Rainfall > ${convertValue(1200, "precipitation", unit, null, false)}`} </h4>
                    </div>
                    {
                        generalslabs === null ? <div className="flex align-items-center justify-content-center"><ProgressSpinner /></div> : generalslabs && generalslabs.series && generalslabs.series.length > 0 && generalslabs.series[0].data && generalslabs.series[0].data.length > 0 ? showCharts(generalslabs) : showMessage("No sites are available")
                    }</div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <div className="flex align-items-center justify-content-center">
                        <h4 className="header">{"Cumulative Snowfall for Last 6 Months"} </h4>
                    </div>
                    {
                        snow === null ? <div className="flex align-items-center justify-content-center"><ProgressSpinner /></div> : snow && snow.series && snow.series.length > 0 && snow.series[0].data && snow.series[0].data.length > 0 ? showCharts(snow) : showMessage("No sites are available")
                    }
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <div className="flex align-items-center justify-content-center ">
                        <h4 className="header">{`Number of Days sites Experiencing Snowfall >${convertValue(100, "precipitation", unit, null, false)}`} </h4>
                    </div>
                    {
                        snowgreat100 === null ? <div className="flex align-items-center justify-content-center"><ProgressSpinner /></div> : snowgreat100 && snowgreat100.series && snowgreat100.series.length > 0 && snowgreat100.series[0].data && snowgreat100.series[0].data.length > 0 ? showCharts(snowgreat100) : showMessage("No sites are available")
                    }
                </div>
            </div>
            <div className="flex col-12">
                <div className="card  col-12 mr-3 h-full">
                    <div className="flex align-items-center justify-content-center"><h4 className="header">{` Sites Experiencing Wind Speed Greater Than ${convertValue(30, "speed", unit, null, false)}`} </h4></div>
                    {windspeed === null ? <div className="flex align-items-center justify-content-center"><ProgressSpinner /></div> : windspeed && windspeed.series && windspeed.series.length > 0 && windspeed.series[0].data && windspeed.series[0].data.length > 0 ? showCharts(windspeed) : showMessage("No sites are available")}
                </div>
            </div>
        </div>


    )

}

export default WeatherDataDashBooard;