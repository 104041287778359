import React, { useEffect, useState, useRef } from 'react'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import * as XLSX from "xlsx";
import moment from 'moment';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { plazaServices } from '../tollPlaza/plazaReportService';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';
import walmartService from "./walmartService"
import { Toast } from 'primereact/toast';

export const TittleListingStatus = () => {
    const [dateRange, setDateRange] = useState([]);
    const [data, setdata] = useState([])
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState(null);
    const [isbn, setIsbn] = useState(null);
    const [metaData, setMetaData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [update, setUpdate] = useState(null);
    const [price, setPrice] = useState(null);
    const [first, setFirst] = useState(0);
    const [skip, setSkip] = useState(0);
    const [searchIsbn, setSearchIsbn] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const history = useHistory();
    const toast = useRef()
    const debounceTimer = useRef(null);
    const serviceId = localStorage.getItem("serviceId");

    const initReport = () => {
        const payload = {
            filter: {},
            projection: {
                _id: 0,
                bisacSubjectCodes: 0,
                bookFormat: 0,
                inputFileName: 0,
            },
            "sort": [["createdDate", -1]]
        }
        if (dateRange[1]) {
            payload.filter["createdDate"] = {
                $gte: dateRange[0],
                $lte: dateRange[1]
            }
        }
        payload["skip"] = data.length;
        payload["limit"] = 10;
        console.log("sta",status)
        if (status && status!=="All") {
            payload.filter["walmart.listingStatus"] = status;
        }
        if (searchIsbn) {
            payload.filter["isbn13"] = parseInt(searchIsbn);
        }
        Promise.all([plazaServices.general(serviceId, payload, "nq-processing-status")]).then((res) => {
            if (res[0].length < 10) setSkip(-1);
            setdata([...data, ...res[0]]);
        })

    }

    useEffect(() => {
        if (!searchIsbn && !status && !dateRange.length) initReport()
        else {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
            debounceTimer.current = setTimeout(() => {
                initReport();
            }, 500);
        }
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        };
    }, [searchIsbn, status, dateRange[1]]);

    const addUpdate = (bool) => {
        const data = { uploadTypeList: bool };
        history.push({
            pathname: '/wm-upload',
            state: { data }
        });
    }

    const handleDateChange = (e) => {
        setSkip(0);
        setFirst(0)
        setdata([])
        const [startDate, endDate] = e.value;
        let formattedStartDate = null;
        let formattedEndDate = null;
        if (startDate !== null) {
            formattedStartDate = moment(startDate).startOf('day').toDate();
        }
        if (endDate !== null) {
            formattedEndDate = moment(endDate).endOf('day').toDate();
        }
        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
    };

    const StyledCalendar = () => {
        return (
            <div className="p-field  w-full">
                <Calendar
                    id="range"
                    value={dateRange}
                    placeholder="Select Date"
                    onChange={handleDateChange}
                    selectionMode="range"
                    showIcon
                    readOnlyInput
                    className="p-calendar flex align-items-center bg-white"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',
                    }}
                    format="dd-mm-yy"
                />
            </div>
        );
    };

    const metadataShow = () => {
        return (
            <Dialog visible={visible} style={{ width: '60vw' }} onHide={() => {
                setVisible(false)
                setShowDescription(false)
            }}>
                <div className='grid col-12 justify-content-center'>
                    <h3 className='mb-2'>Details for ISBN {metaData.isbn13} </h3>
                    <div className='flex flex-wrap justify-content-between col-12'>
                        <div className='flex  '>
                            <h4>Tittle : </h4>
                            <p className=' ml-1 text-xl'>{metaData.productName}</p>
                        </div>
                        <div className='flex'>
                            <h4>Authors :</h4>
                            <p className='ml-1 text-xl'>{metaData.author}</p>
                        </div>
                        <div className='flex'>
                            <h4>Price :</h4>
                            <p className='ml-1 text-xl'>{metaData.price}</p>
                        </div>
                    </div>
                    <div className='flex flex-wrap  justify-content-between col-12'>
                        <div className='flex  '>
                            <h4>Publisher : </h4>
                            <p className=' ml-1 text-xl'>{metaData.publisher}</p>
                        </div>
                        <div className='flex'>
                            <h4>Number of pages :</h4>
                            <p className='ml-1 text-xl'>{metaData.numberOfPages}</p>
                        </div>
                    </div>
                    <div className='flex flex-column justify-content-between col-12'>
                        <h4 className='m-0 flex align-items-center'>
                            Short Description
                            {showDescription ? (
                                <Button className='bg-white m-0 p-0 ml-2' onClick={() => setShowDescription(!showDescription)}>
                                    <i className='pi pi-angle-down' style={{ color: 'black' }}></i>
                                </Button>
                            ) : (
                                <Button className='bg-white m-0 p-0 ml-2' onClick={() => setShowDescription(!showDescription)}>
                                    <i className='pi pi-angle-up' style={{ color: 'black' }}></i>
                                </Button>
                            )}
                        </h4>
                        <p>{showDescription && metaData.shortDescription}</p>
                    </div>
                    {showDescription && <div className='col-12 flex flex-column justify-content-center mt-1'>
                        <h4 >Cover Page Image</h4>
                        {<img src={metaData.mainImageUrl}></img>}
                    </div>}
                    {showDescription && metaData.itemDepth && metaData.itemHeight && metaData.itemWeight && metaData.itemWidth &&
                        <div className='col-12 flex flex-column justify-content-center mt-1'>
                            <h4 >Dimensions</h4>
                            <div className='flex justify-content-between'>
                                <p>{metaData.itemDepth && `${metaData.itemDepth}inches`}</p>
                                <p>{metaData.itemHeight && `${metaData.itemHeight}inches`}</p>
                                <p>{metaData.itemWeight && `${metaData.itemWeight}lbs`}</p>
                                <p>{metaData.itemWeight && `${metaData.itemWidth}inches`}</p>
                            </div>
                        </div>}
                </div>
            </Dialog>
        )
    }

    const setMeta = (e) => {
        e.data.author = e.data.author.split('|').join(', ');
        const tempElement = document.createElement('div');
        tempElement.innerHTML = e.data.shortDescription;
        e.data.shortDescription = tempElement.textContent || tempElement.innerText;
        setMetaData(e.data);
        setVisible(true)
    }

    const editClick = (row) => {
        setIsbn(row.isbn13)
        setEdit(true)
    }

    const Header = (
        <ColumnGroup className="bg-cyan-500 text-white card monthly-details">
            <Row>
                <Column header="Submission Date" className="bg-cyan-500 text-white"></Column>
                <Column header="Listing Partner" className="bg-cyan-500 text-white"></Column>
                <Column header="ISBN" className="bg-cyan-500 text-white"></Column>
                <Column header="Title" className="bg-cyan-500 text-white"></Column>
                <Column header="Listing Status" className="bg-cyan-500 text-white"></Column>
                <Column header="Error Message" className="bg-cyan-500 text-white"></Column>
                <Column header="" className="bg-cyan-500 text-white"></Column>
            </Row>
        </ColumnGroup>
    );

    const serachBox = (Func, placeHolder, val) => {
        return (
            <div className="p-field  w-12">
                <InputText
                    className="p-calendar flex align-items-center bg-white w-12"
                    style={{
                        borderRadius: '17px',
                        border: '2px solid #0C6291',
                    }}
                    onChange={(e) => {
                        Func(e.target.value)
                        if (placeHolder !== "Price") {
                            setSkip(0);
                            setFirst(0)
                            setdata([])
                        }
                    }}
                    placeholder={placeHolder}
                    value={val ?? ""}
                ></InputText>
            </div>
        );
    }

    const onDownload = async () => {
        let res = await Filter()
        let Data = [];
        let filename = "listingStatus.xlsx";
        res.map((item) => {
            let temp = {};
            for (const key in item) {
                if (key !== "_id") temp[key] = item[key];
            }
            Data.push(temp);
        })
        let ws = XLSX.utils.json_to_sheet(Data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
    };

    const DownloadButton = (icon, text, Func, data) => {
        return (
            <div className="download-box mt-1">
                <Button className=" p-button-text " onClick={() => Func(data)} label={text}>
                    <i className={`pi ${icon} ml-2`} style={{ color: '#0C6291' }}></i>
                </Button>
            </div>
        );
    };

    const updateOperation = async () => {
        let formData = new FormData()
        const isbn13 = isbn;
        const data = price !== null ? [{ isbn13, price }] : [{ isbn13 }];
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const output = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([output], { type: 'application/octet-stream' });
        const file = new File([blob], 'isbn_price.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        formData.append("file", file);
        formData.append("fileTypeList", false);
        formData.append("reason", update)
        formData.append("serviceId", serviceId);
        if (update) {
            walmartService.uploadFeedFile(formData).then((res) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: "ISBN updated successfully", life: 3000 });
            })
        }
        else toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please Fill All Fields', life: 3000 });

    }

    const generalButton2 = (icon, text, Func, bool, bgColour, none) => {
        return (
            <div className={none ? "" : `ml-1 mr-4`}>
                <Button className=" text-white " style={{ backgroundColor: `${bgColour}` }} onClick={() => Func(bool)} label={text}>
                    <i className={`pi ${icon} ml-2`} style={{ color: '#FFFFFF' }}></i>
                </Button>
            </div>
        );
    };

    const statusList = [
        { label: "All", value: "All" },
        { label: "Success", value: "Success" },
        { label: "Failure", value: "Failed" },
        { label: "Submitted", value: "Submitted" }
    ]

    const updateList = [
        { label: "Update Price", value: "update" },
        { label: "Suspend Listing", value: "suspendListing" },
        { label: "Retire Listing", value: "deleteListing" }
    ]

    const TollSelector = (specific, list, setFunc, placeHolder) => {
        const customTemplate = (option) => (
            <div className="flex align-items-center">
                <i className="" style={{ color: '#0C6291' }}></i>
                <span>{option.label}</span>
            </div>
        );
        return (
            <div className="p-field  w-full">
                <Dropdown
                    id="tollDropdown"
                    value={specific}
                    options={list}
                    onChange={(e) => {
                        setFunc(e.value)
                        if (list !== updateList ) {
                            setSkip(0);
                            setFirst(0)
                            setdata([])
                        }
                    }}
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="" style={{ marginRight: '8px' }}></i>
                            {placeHolder}
                        </span>
                    }
                    itemTemplate={customTemplate}
                    className="p-dropdown flex align-items-center bg-white"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291'
                    }}
                />
            </div>
        );
    };

    const showEditButton = (row) => {
        if (row.walmart.listingStatus !== "Success") return null
        return <div className='flex lg:col-2 col-6'>{DownloadButton("pi-pencil", "", editClick, row)}</div>
    }

    const Filter = async () => {
        let payload = {
            filter: {}
        };
        if (dateRange.length > 0) {
            payload.filter["listingDate"] = {
                $gte: dateRange[0],
                $lte: dateRange[1]
            }
        }
        if (status) payload.filter["walmart.listingStatus"] = status;
        if (searchIsbn) payload.filter["isbn13"] = parseInt(searchIsbn);
        let response = await plazaServices.general(serviceId, payload, "nq-processing-status");
        return response;
    }
    const dateShow = (item) => {
        let temp = moment(item.createdDate, 'YYYY-MM-DD').format('MMM, Do YYYY');
        return <span>{temp}</span>
    }

    const statusShow = (row) => {
        let rung = "";
        if (row.walmart.listingStatus === "Success") rung = 'text-green-600';
        else rung = 'text-red-700'
        return <span className={rung}>{row.walmart.listingStatus}</span>
    }

    const onReset = () => {
        setDateRange([]);
        setSearchIsbn(null);
        setStatus(null)
    }

    return (
        <div className='grid flex col-12 mt-2 justify-content-center ml-1'>
            <Toast ref={toast} />
            <div className='col-12 flex flex-wrap sm:flex  justfy-content-between'>
                <div className='flex lg:col-3 col-12'>{serachBox(setSearchIsbn, "Search By ISBN", searchIsbn)}</div>
                <div className='flex lg:col-3 col-12'>{StyledCalendar()}</div>
                <div className='flex lg:col-3 col-12'>{TollSelector(status, statusList, setStatus, "Select Status")}</div>
                <div className="col-12 md:col-2 mt-1">
                    <Button label="Reset" className=" p-button-danger" onClick={onReset} />
                </div>
            </div>
            <div className='col-12 card flex flex-wrap justify-content-end'>
                <div className='flex lg:col-12 col-6 justify-content-end'>
                    {generalButton2("pi-plus", "", addUpdate, true, "#0C6291")}
                    {generalButton2("", "Bulk Item Update", addUpdate, false, "#0C6291")}
                    {generalButton2("pi-download", "Download", onDownload, "", "#0C6291")}
                </div>
                <div className='col-12 flex justify-content-center'>
                    <DataTable headerColumnGroup={Header} className="datatable-box card w-full " value={data} selectionMode='single' responsiveLayout="scroll" rows={5} paginator onRowSelect={(row) => {
                        setVisible(true)
                        setMeta(row)
                    }}
                        first={first} onPage={(e) => {
                            setFirst(e.first)
                            if (e.first > first && skip !== -1) {
                                let pre = skip + 11;
                                setSkip(pre);
                                initReport()
                            };
                        }}>
                        <Column className="bg-white font-medium  surface-300 bg-gray-200" style={{ color: "#5E6771" }} body={dateShow}></Column>
                        <Column className="bg-white font-medium surface-300 bg-gray-200" style={{ color: "#5E6771" }} field="partner"></Column>
                        <Column className="bg-white font-medium  surface-300 bg-gray-200" style={{ color: "#5E6771" }} field="isbn13"></Column>
                        <Column className="bg-white font-medium  surface-300 bg-gray-200" style={{ color: "#5E6771" }} field="productName"></Column>
                        <Column className="bg-white font-medium  surface-300 bg-gray-200" style={{ color: "#5E6771" }} body={statusShow} ></Column>
                        <Column className="bg-white font-medium  surface-300 bg-gray-200" style={{ color: "#5E6771" }} field="walmart.listingErrors.msg"></Column>
                        <Column className="bg-white font-medium  surface-300 bg-gray-200" style={{ color: "#5E6771" }} body={showEditButton}></Column>
                    </DataTable>
                </div>
            </div>
            {metadataShow()}
            <Dialog visible={edit} style={{ width: '47vw' }} onHide={() => {
                setEdit(false);
                setUpdate(null)
                setPrice(null)
            }}>
                <div className='grid col-12 justify-content-center'>
                    <div className='flex col-12 justify-content-center'><h3 className='mb-2'>Update Item </h3></div>
                    <div className='flex lg:col-6 col-12 justify-content-center'>
                        {TollSelector(update, updateList, setUpdate, "Select Update")}
                    </div>
                    {update === "update" && <div className='flex lg:col-3 col-12'>{serachBox(setPrice, "Price", price)}</div>}
                    {<div className='flex lg:col-1 col-12'>{generalButton2("pi-refresh", "update", updateOperation, "", "#0C6291", true)}</div>}
                </div>
            </Dialog>
        </div>
    )
}