import axios from "axios";
async function general(serviceID, payload,collection) {
    let res;
    if(payload && payload.operation && payload.operation==="aggregate")res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + `/${collection}`, payload);
    else res=await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + `/${collection}`, payload)
    if (res.status !== 200) return [];
    return res.data;
}

export const plazaServices = {
    general
}