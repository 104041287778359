import React, { useRef, useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import axios from "axios";
import DBservice from "../service/dbService";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { getPaths } from "../utilities/utillFunction";
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ListBox } from 'primereact/listbox';

const style = {
    border: { border: "1px", borderStyle: "solid", borderColor: "#e4e4e4" },
    bg : "#264653"
}

const UploadFile = () => {
    const toast = useRef(null);
    const history = useHistory();
    const jwtToken = localStorage.getItem("token");
    const serviceId = localStorage.getItem("serviceId");

    const url = process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/upload-config-file/" + serviceId;

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);
    const [fileStatus, setFileStatus] = useState([]);
    const handelFile = (e) => {
        setFileName(e.target.files[0].name);
        setFile(e.target.files[0]);
    };

    useEffect(async () => {
        let res = await DBservice.getConfigFileUploadStatus();
        setFileStatus(res);
    }, [])

    const handleSubmit = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("serviceId", serviceId);

        let header = {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + jwtToken,
        };
        toast.current.show({ severity: "info", summary: "Message", detail: "Uploading is in the progress, please visit after sometime", life: 9000 });

        let res = await axios({
            method: "post",
            url: url,
            data: formData,
            headers: header,
        });
    
        if (res.data.responseCode === 200) {
            toast.current.show({ severity: "success", summary: "Success", detail: res.data.message, life: 9000 });
            setFile(null);
            setLoading(false);
            setFileName("");
            document.getElementById("uploadConfig").value = ""
        } else {
            console.error(res.data.message);
            toast.current.show({ severity: "error", summary: "Error", detail: res.data.message, life: 9000 });
            setLoading(false);
            setFile(null);
            setFileName("");
            document.getElementById("uploadConfig").value = ""
        }
    };

    const timeTemplate = (data) => {
        return moment(data.ts).format("DD/MM/YYYY")
    }

    return (
        <div className="">
            <Toast ref={toast} />
            <div className="">
                <div className="card col-12">
                    <div className="flex w-5 mx-auto justify-content-center">
                        <a href="/configFile.zip" download style={{textDecoration:"none",color:"inherit"}}>
                            <div className="mr-8">
                                <div className="mx-auto doc">
                                    <i className="pi pi-download"></i>
                                </div>
                                <span className="mt-3 block">Download Template</span>
                            </div>
                        </a>

                        <div className="ml-8">
                            <div className="mx-auto doc active">
                                <i className="pi pi-upload"></i>
                            </div>
                            <span className="mt-3 block">Upload Config</span>
                        </div>
                    </div>
                    <div className="flex flex-column w-5 h-13rem mt-3 mx-auto border-1 border-dashed border-400 border-round-md justify-content-center align-items-center">
                        <div className="flex mb-3 flex-column align-items-center">
                            <div className="fileUpload">
                                <div className="text-6xl">
                                    <i className="pi pi-upload text-4xl"></i>
                                </div>
                                <span>{fileName}</span>
                                <span className="mt-3 mb-3 block">Upload the Config File</span>
                                <input id="uploadConfig" type="file" className="upload" onChange={(e) => handelFile(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            </div>
                            {fileName !== "" ? <Button label="Upload" onClick={handleSubmit} loading={loading} /> : <Button label="Upload" onClick={handleSubmit} disabled />}
                        </div>
                    </div><br />
                </div>
                <UploadConfigFileUi toast={toast}/>
                {
                    fileStatus && fileStatus.length ?
                        <div className="col-12">
                            <DataTable value={fileStatus} header={"Uploading History"} paginator={true} rows={10} responsiveLayout="scroll">
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Upload Time" style={{ width: "100px" }} field={timeTemplate}></Column>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Total things" style={{ width: "100px" }} field={"total"}></Column>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Pending" style={{ width: "100px" }} field={"pending"}></Column>
                                <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Uploaded" style={{ width: "100px" }} field={"added"}></Column>
                            </DataTable>
                        </div>
                        : null
                }
            </div>
        </div>
    );
};

export default UploadFile;

const UploadConfigFileUi =  ({toast}) =>{

    const [addUI, setAddUI] = useState(null)
    const [inputFormat, setInputFormat]  = useState("")
    const [inputPaths, setInputPaths] = useState()
    const [gateways, setGateways] = useState([])
    const [inputType, setInputType] = useState([])
    const [inputFormatData, setInputFormatData] = useState([])
    const [dbAllowedKey, setDBAllowedKey] = useState([])
    const [selectedInputType, setSelectedInputType] = useState({})
    const [disableBtn, setDisableBtn] = useState(false)
    const [err, setErr] = useState("")
    const allowedDBKeys = useRef([])
    useEffect(()=>{
        const payload = {
            projection : {
                "make" : 1,"model" : 1, "connectivity.cloudConnectivityProtocol":1,"_id":0
            }
        }
        const payload1 = {
            projection: { "_id": 0 }
        }
        const payload2 = {
            projection : {
                "_id":0, "fid":1, "inputType":1, "inputFormat":1
            }
        }
        Promise.all([DBservice.getGatewayData(payload), DBservice.getInputFormat(payload2), DBservice.getInputType(payload1)]).then((res)=>{
            setGateways(res[0])
            setInputType(res[2])
            setInputFormatData(res[1])
        })
    },[])

    const handleAddButtonClick = (event, toAdd, toClose) =>{
            if(toAdd === addUI || toClose) {
                setAddUI(null)
                setInputPaths(null)
                setSelectedInputType(null)
                setInputFormat("")
                setErr({})
            }
            else setAddUI(toAdd)
        }
    const validateInputFormat = async (input = "") =>{
        setErr({})
        let validateInputRes = validateInput(input)
        if(validateInputRes){
            setErr({jsonErrMsg: validateInputRes})
            setInputPaths(null)
            return
        }
        let validateRes = validateJSON(input)
        if (validateRes) {
            let payload = {
                filter : {
                    serviceTypeName : JSON.parse(localStorage.getItem("service")).serviceTypeName
                },
                projection : { "_id":0, "dbKeys":1}
            }
           let dbAllowedKeysRes = await DBservice.getDBAllowedKeys(payload)
            if(dbAllowedKeysRes && dbAllowedKeysRes[0]){
                dbAllowedKeysRes = dbAllowedKeysRes[0]["dbKeys"].filter(item=>{
                    return item.enabled === true
                })
            }
            let getPathsRes = getPaths(validateRes)
            let tempObj = {}
            getPathsRes && Object.keys(getPathsRes).map((item) => {
                tempObj[item] = {
                    path: getPathsRes[item],
                    mulFact: 1
                }
            })
            allowedDBKeys.current =  dbAllowedKeysRes
            setDBAllowedKey({})
            setInputPaths(tempObj)
        }
        else{
            setErr({jsonErrMsg: "Invalid JSON format/Schema"})
            setInputPaths(null)
        }
    }
    const handleAllowedDBKeys = (e, name) =>{
        setDBAllowedKey({...dbAllowedKey, [name]: e.value})
        setInputPaths((prev) => ({ ...prev, [e.target.name]: { ...prev[e.target.name], alias : e.value.key } }))
    }
    const validateInput = (input, data) => {
        if(selectedInputType && !(selectedInputType.type)) return "Please Select Input Type" 
        if(!data && !input ) return "Enter Gateway Payload Format"
        if(data && (data?.mulFact <= 0)) return "Multiplier cannot be 0 or below"
        if(data && !data?.alias) return "Please Select Abbreviation Key for all Fields"
        else return false
    }
    const handleSubmit = async () =>{
        setDisableBtn(true)
        let payloadKey = Object.keys(inputPaths)
        for(let i = 0; i<payloadKey.length; i++){
            let data = inputPaths[payloadKey[i]]
            let validationRes = validateInput(null, data)
            console.log(validationRes)
            if(validationRes){
                setErr({submitErr: validationRes})
                setDisableBtn(false)
                return
            }
        }
        let payload = {
            "uid": localStorage.getItem("uid"),
            "serviceId": localStorage.getItem("serviceId"),
            "inputFormat": inputFormat.trim(),
            "inputType": selectedInputType.type,
            "payload" : inputPaths 
        }
        DBservice.addInputFormat(payload).then((res)=>{
            if(res && res.success && res.responseCode === 200){
                res = res.responseData 
                let temp = {fid:res.fid, inputFormat : res.inputFormat, inputType: res.inputType}
                setInputFormatData([...inputFormatData, temp])
                handleAddButtonClick(null, null, true)
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Add input Format Success" });
                setDisableBtn(false)
            }
            else{
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.message });
                setDisableBtn(false)

            }
           
        })
    }

    const validateJSON = (input) =>{
        try{
            input= input.trim().replaceAll("'", '"')
            let parsedJSON = JSON.parse(input)
            return parsedJSON
        }
        catch(err){
            return false
        }
    }
    return (
        <div className="border">
            <div className="col-12 grid">
                <div className="col-12 lg:col-6">
                    <div className="col-10">
                        <Accordion>
                            <AccordionTab header="Gateway List">
                                <DataTable value={gateways} paginator={true} rows={5} responsiveLayout="scroll">
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Make" style={{ width: "100px" }} field={"make"}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Model" style={{ width: "100px" }} field={"model"}></Column>
                                    <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Protocol" style={{ width: "100px" }} field={"connectivity.cloudConnectivityProtocol"}></Column>
                                </DataTable>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div className="col-10">
                        <Accordion>
                            <AccordionTab header="Input Type List">
                                <ListBox options={inputType} optionLabel="type" filter />
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div className="col-12 flex">
                        <div className="col-10">
                            <Accordion>
                                <AccordionTab header="Input Format List">
                                    <DataTable value={inputFormatData} paginator={true} rows={5} responsiveLayout="scroll">
                                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Input Format ID" field={"fid"}></Column>
                                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Input Type" field={"inputType"}></Column>
                                        <Column headerClassName="color1-bg" bodyClassName="color2-bg" header="Input Format" field={"inputFormat"}></Column>
                                    </DataTable>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="col-2"><Button style={{ marginTop: "1.4rem" }} label={addUI === "inputFormat" ? "Close" : "Add New"} onClick={(e) => handleAddButtonClick(e, "inputFormat")}></Button></div>
                    </div>
                </div>
           
            {addUI === "inputFormat" ? <div className="col-12 lg:col-6">
                <div className="card" style={{minHeight:"18rem"}}>
                    <div className="col-12 lg:col-6">
                        <label>Select Input Type</label>
                        <Dropdown value={selectedInputType} options={inputType} onChange={(e)=>setSelectedInputType(e.value)} className="bg-white w-full" optionLabel="type" placeholder="Input Type " />
                    </div>
                    <div className="col-12">
                    <label>Enter Input Format</label>
                    <InputTextarea id="address" className="w-full" value={inputFormat} onChange={(e) => setInputFormat(e.target.value)} rows="3" />
                    <div className="w-full flex justify-content-between align-items-center" style={{marginTop: "0.5rem" }}>
                    <div style={{ color: "red", float:"left"}}><h6 className="text-center">{err.jsonErrMsg}</h6></div>
                    <Button label="Validate" disabled={disableBtn}  onClick={() => validateInputFormat(inputFormat)}></Button>
                    </div>
                    
                    </div>
                    {inputPaths && <><table className="w-full" style={{ borderCollapse: "collapse", clear: "right" }}>
                        <thead className="bg-primary">
                            <tr className="text-left">
                                <th className="th">Format</th>
                                <th className="th">Multiplier</th>
                                <th className="th">Abbreviation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(inputPaths).map((item, index) => {
                                return <tr style={style.border} key={index}>
                                    <td>{item}</td>
                                    <td><InputText className="p-2 border-noround border-1 border-700" type="Number" name={item} value={inputPaths[item].mulFact} onChange={(e) => setInputPaths((prev) => ({ ...prev, [e.target.name]: { ...prev[e.target.name], mulFact: e.target.value } }))} style={{ width: "5rem" }} /></td>
                                    <td><Dropdown value={dbAllowedKey[item]} options={allowedDBKeys.current} name={item} onChange={(e)=>handleAllowedDBKeys(e, e.target.name)} className="bg-white w-full" optionLabel="key" placeholder="Select Abbreviation" /></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <br></br>
                        <div className="w-full flex justify-content-between">
                            <div style={{ color: "red", float:"left"}}><h6 className="text-center">{err.submitErr}</h6></div>
                            <Button label="Add Input Format" disabled={disableBtn} onClick={handleSubmit}></Button>
                        </div></>}
                    
                </div> </div> : null}
                </div>
        </div>
    )
}
