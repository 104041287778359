import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import service from "../service/dbService"
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";

const DeletAccount = () => {
  const [email, setEmail] = useState(null);
  const [code, setCode] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [countryCodeVal, setCountryCodeVal] = useState("+" + getCountryCallingCode(localStorage.getItem("defaultCountryCode")) || "")
  const [showPhoneInput, setShowPhoneInput] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const inputTypeNumber = useRef(false)
  let defaultCountryCode = localStorage.getItem("defaultCountryCode")

  const validateInputs = () => {
    if (!email) return "Please enter email/mobile";
    if (isNumber(email) && !isValidPhoneNumber(countryCodeVal + email)) return "Enter valid mobile no"
    if (email && !isNumber(email)) {
      let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let res = emailRegex.test(email);
      if (!res) return "enter valid email";
    }
  }

  const onContinue = async (e) => {
    setErrMsg("")
    e.preventDefault();
    let validationRes = validateInputs();

    if (typeof validationRes === "string") {
      setErrMsg(validationRes);
    }
    else {
      setDisableBtn(true)
      let res = await service.deleteMyAccount({ username: email, otp: false})
      if (res.responseData && res.success && res.responseCode === 200) setCodeSent(true)
    }
  }

  function isNumber(n) {
    if (n == "" || n == null || n == undefined) return false
    return Number(n) == n
  }

  const handleChange = (value) => {
    setEmail(value)
    if (!value.trim()) {
      setShowPhoneInput(false)
      inputTypeNumber.current = false
      return
    }
    let valIsNo = isNumber(value)
    if (valIsNo && inputTypeNumber.current === false) {
      setShowPhoneInput(true)
      inputTypeNumber.current = valIsNo
    }
    else if (!valIsNo && inputTypeNumber.current === true) {
      setShowPhoneInput(false)
      inputTypeNumber.current = false
    }
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    let res = await service.deleteMyAccount({ username: email, otp : code})
    if (res.responseData && res.success && res.responseCode === 200) setErrMsg(res.responseData)
  }

  return (
    <div className="pages-body flex flex-column login-img">
      <div className=" align-self-center  mt-auto mb-auto">
        <div className=" card flex flex-column col-12" style={{ color: "#616161" }}>
          <div className="align-self-center">
            <img alt="" src="./images/nexLogo.svg" className="logo-fixing" /><br />
          </div>
          {
            !codeSent ?
              <>
                <form >
                  <div className="mb-3 px-3 font-bold" style={{ textAlign: "center", fontSize: "1.2rem" }}>Enter Your Email Or Phone No</div>
                  <div className="mb-1 px-3">
                    <label>Email / Phone</label>
                  </div>
                  <div className="mb-3 px-3">
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        {showPhoneInput && <PhoneInput id="userPhoneInput" style={{ borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: "7em" }} className="p-inputtext p-component p-filled w-full" value={countryCodeVal} onChange={setCountryCodeVal} international defaultCountry={defaultCountryCode} />}
                        <InputText type="text" id="inputgroup1" autoFocus={true} onChange={(e) => handleChange(e.target.value)} />
                      </span>
                    </div>
                  </div>
                  <div className="text-center pt-0 errorstyle mb-1" style={{ color: "red" }}>{errMsg}</div>
                  <div className="mb-3 px-3">
                    <Button className="login-button mb-3 px-3 col-12" style={{ padding: "0.75rem 0.75rem" }} disabled={disableBtn} label="Get OTP" onClick={onContinue}></Button>
                  </div>
                </form>
              </>
              :
              <>
                <form>
                  <div className="mb-3 px-3 font-bold text-center align-self-center" style={{ fontSize: "1.2rem" }}>Enter verification code</div>
                  <p className='text-center text-600	'> Verification Code Sent to Registered Mob No.</p>
                  <div className=" mb-1 px-3">
                    <label>Verification Code</label>
                  </div>
                  <div className="mb-3 px-3">
                    <InputText className="col-12" style={{ padding: "0.75rem 0.75rem" }} type="text" id="code" onChange={(e) => setCode(e.target.value)} />
                  </div>
                  <div className="text-center mb-3 px-3" style={{ color: "red" }}>{errMsg}</div>
                  <div className="mb-3 px-3">
                    <Button className="p-button-danger mb-3 px-3 col-12" label="Delete Account" onClick={handleDelete}></Button>

                  </div>
                </form>
              </>
          }
        </div>
      </div>
    </div>
  )
}

export default DeletAccount;
