import axios from "axios"
async function getBins(min, max, Size) {
  min = Math.floor(min);
  max = Math.floor(max + 1);
  let stepSize;
  if (min < 0) stepSize = (max - (min * -1)) / Size;
  else stepSize = (max - min) / Size;
  stepSize = Math.floor(stepSize);
  if (stepSize === 0) stepSize = 1;
  if (stepSize === 9) stepSize++;
  if (stepSize === 23) stepSize = stepSize + 2;
  let bins = [];
  for (let i = 0; i < Size; i++) {
    let temp = [min, min + stepSize];
    bins.push(temp);
    min = min + stepSize;
  }
  return bins;
}

async function getMinimun(serviceId) {
  const query = {
    operation: "aggregate",
    aggregate: [
      {
        $group: {
          _id: null,
          "min_sum_of_gt_40c_temp_days": { $min: "$sum_of_gt_40c_temp_days" },
          "max_sum_of_gt_40c_temp_days": { $max: "$sum_of_gt_40c_temp_days" },
          "min_sum_of_lt_0c_temp_days": { $min: "$sum_of_lt_0c_temp_days" },
          "max_sum_of_lt_0c_temp_days": { $max: "$sum_of_lt_0c_temp_days" },
          "min_sum_totalsnow_mm": { $min: "$sum_totalsnow_mm" },
          "max_sum_totalsnow_mm": { $max: "$sum_totalsnow_mm" },
          "min_sum_of_gt_100mm_snow_days": { $min: "$sum_of_gt_100mm_snow_days" },
          "max_sum_of_gt_100mm_snow_days": { $max: "$sum_of_gt_100mm_snow_days" },
          "min_sum_of_gt_50mm_snow_days": { $min: "$sum_of_gt_50mm_snow_days" },
          "max_sum_of_gt_50mm_snow_days": { $max: "$sum_of_gt_50mm_snow_days" },
          "min_sum_of_gt_50mm_rain_days": { $min: "sum_of_gt_50mm_rain_days" },
          "max_sum_of_gt_50mm_rain_days": { $max: "sum_of_gt_50mm_rain_days" },
          "min_sum_totalprecip_mm": { $min: "sum_totalprecip_mm" },
          "max_sum_totalprecip_mm": { $max: "sum_totalprecip_mm" }
        }
      }
    ]
  };


  const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-weather-aggregated`, query);
  return res.data;
}

async function generalSlab(serviceId, field, minMax) {
  let min = minMax[`min_${field}`] ? minMax[`min_${field}`] : 0;
  let max = minMax[`max_${field}`] ? minMax[`max_${field}`] : 0;
  if (field === "sum_totalprecip_mm") {
    min = 1200;
    max = 3699;
  }
  if (field === "sum_of_gt_50mm_rain_days") {
    min = 5;
    max = 29;
  }
  let bins = await getBins(min, max, 5);
  let data = []
  if (field === "sum_totalsnow_mm") bins[0][0] = 5;
  let def = `${bins[bins.length - 1][1]}+`
  for (let i = 0; i < 5; i++) {
    data.push({ case: { $lte: [`$${field}`, bins[i][1]] }, then: `${bins[i][0]}-${bins[i][1]}` })
  }
  let key = 0;
  if (field === "sum_totalprecip_mm") {
    key = 1200
  }
  if (field === "sum_totalsnow_mm") key = 5;
  if (field === "sum_of_gt_50mm_rain_days") {
    key = 5;
  }

  let nextquery = {
    operation: "aggregate",
    aggregate: [
      {
        $match: {
          [field]: {
            $gt: key
          },

        }
      },
      {
        $project: {
          Range: {
            $switch: {
              branches: data,
              default: def
            }
          }
        }
      },
      {
        $group: {
          _id: "$Range",
          count: { $sum: 1 }
        }
      }

    ],
  };
  const finalres = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-weather-aggregated`, nextquery);
  let ans = [];
  bins.map((item) => {
    let temp = {
      _id: `${item[0]}-${item[1]}`,
      count: null
    }
    ans.push(temp);
  }
  )
  for (let i = 0; i < ans.length; i++) {
    for (let j = 0; j < finalres.data.length; j++) {
      if (ans[i]._id === finalres.data[j]._id) {
        ans[i].count = finalres.data[j].count;
      }
    }
  }
  return ans;

}

async function getStates(serviceId) {
  const payload = {
    operation: "aggregate",
    aggregate: [
      {
        $group:
        {
          _id: "$st",

        }
      },
      {
        $sort: {
          "count": -1
        }
      }

    ],
  };

  const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-site-addresses", payload);
  return res.data;

}

async function getCity(serviceId, state) {
  const payload = {
    filter: {
      st: state
    }
  };
  const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-site-addresses-cityWise", payload);
  return res.data;

}

async function stateWise(serviceId, field) {
  let key = 30;
  if (field === "temperature_difference") {
    key = 35;

  }
  let query = {
    operation: "aggregate",
    aggregate: [
      {
        $match: {
          [field]: { $gt: key }
        }
      },
      {
        $group: {
          _id: "$st",
          count: { $sum: 1 }
        }
      },
      {
        $sort: { count: -1 }
      }

    ],
  };
  const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-weather-aggregated`, query);
  return res.data;


}

async function siteDetails(min, max, field, serviceId, skip) {
  let payload = {
    operation: "aggregate",
    aggregate: [
      {
        $match: {
          [field]: {
            $gt: min,
            $lte: max,
          },
        },
      },
      { $skip: skip },
      { $limit: 30 },
    ],
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-weather-aggregated`,
      payload
    );
    res.data.map((item) => {
      for (const property in item) {
        if (property === "max_totalsnow_cm") {
          item[property] = item[property] * 10;
        }
        if (typeof (item[property]) === 'number') {
          if (item[property] % 1 !== 0) {
            item[property] = item[property].toFixed(2);
          }
        }
      }
    })
    return res.data;
  } catch (error) {
    throw error;
  }
}

async function statebins(serviceId, state, field, skip) {
  let key = 30;
  if (field === "temperature_difference") {
    key = 35;

  }
  let nextquery = {
    operation: "aggregate",
    aggregate: [
      {
        $match: {
          st: state,
          [field]: { $gt: key }
        }
      },
      { $skip: skip },
      { $limit: 30 }
    ]
  };
  const res = await axios.put(
    `${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-weather-aggregated`,
    nextquery
  );
  res.data.map((item) => {
    for (const property in item) {
      if (typeof (item[property]) === 'number') {
        if (item[property] % 1 !== 0) {
          item[property] = item[property].toFixed(2);
        }
      }
    }
  })
  return res.data;
}

const service = {
  siteDetails,
  generalSlab,
  getStates,
  getCity,
  stateWise,
  statebins,
  getMinimun
}

export default service
