

export const marketplace = [
    {name : "Walmart US", code : "walmart_us"},
    {name : "Walmart_CA", code : "walmart_ca"},
    {name : "Amazon", code : "amazon"}
]

export const listUploadInstructions = {
    message1 : "Download sample Template for Listing ISBN",
    message2 : "Fill the important and necessary columns",
    message3 : "Upload the File"
}

export const updateUploadInstructions = {
    message1 : "Upload excel file with ISBN and a field to update",
    message2 : "Match the file column header with our key",
    message3 : "Upload the File"
}

export const currencyMap = {
  "USD" : "$",
  "INR" : "₹"
}