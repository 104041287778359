import React, { useEffect, useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import TenantService from "../service/tenant";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AssetService } from "../service/AssetService";
import { changeUnit, statesList } from "../Actions/index";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { parseResponse } from "../utilities/utillFunction";
import { TowerService } from "../service/TowerService";
import { removeDomainName } from "../utilities/utillFunction";
import { SetUserServiceDataInLocalStorage } from "../utilities/utillFunction";
import { plazaServices } from "./tollPlaza/plazaReportService";
const ServiceList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [selService, setSelectedService] = useState(null);
    const [selServiceID, setSelectedServiceID] = useState(null);
    const [services, setServices] = useState(null);
    const [showTerms, setShowTerms] = useState(false);
    const [serviceIds, setServiceIds] = useState([]);
    const [filterdPermissionsData, setfilterdPermissionsData] = useState(null);

    useEffect(() => {
        const getPermission = async () => {
            Promise.all([TenantService.getPermissions(), TenantService.getMenuOptions()]).then((res) => {
                let getPermissionResponse = res[0];
                let isAdmin = false;
                let menu = res[1];
                let menumap = {};
                menu.forEach((m) => {
                    if (!menumap[m.serviceId]) menumap[m.serviceId] = m;
                });
                if (getPermissionResponse && getPermissionResponse.length) {
                    let filterdPermissions = [];
                    let serviceIds = getPermissionResponse.map((p) => {
                        if (p.permissions && p.permissions.length && !isAdmin) {
                            let userPersmissions = p.permissions.find((p) => {
                                return p === "UserManagement.WRITE";
                            });
                            if (userPersmissions) isAdmin = true;
                        }
                        if (p.serviceTypeName) {
                            if (menumap[p.serviceId]) p.menu = menumap[p.serviceId];
                            filterdPermissions.push(p);
                        }
                        return p.serviceId;
                    });
                    if (isAdmin && getPermissionResponse[0].service && !getPermissionResponse[0].service.isTermsAccepted) {
                        setServiceIds(serviceIds);
                        setfilterdPermissionsData(filterdPermissions);
                        setShowTerms(true);
                    } else {
                        if (filterdPermissions.length && filterdPermissions.length === 1) {
                            onServiceSelection(filterdPermissions[0].serviceTypeName, filterdPermissions[0], true);
                        } else {
                            setServices(filterdPermissions);
                        }
                    }
                }
            });
            // let getPermissionResponse = await TenantService.getPermissions();
            // if (getPermissionResponse && getPermissionResponse.length) {
            //     let filterdPermissions = getPermissionResponse.filter((p) => p.serviceTypeName);
            //     if (filterdPermissions.length && filterdPermissions.length === 1) {
            //         onServiceSelection(filterdPermissions[0].serviceTypeName, filterdPermissions[0]);
            //     } else {
            //         setServices(filterdPermissions);
            //     }
            // }
        };
        getPermission();
    }, []);

    const createdynamicsitemenu = (data) => {
        let stateMap = {};
        let cityMap = {};
        let towerCount = 0;
        data.forEach((item) => {
            if (stateMap[item.state]) stateMap[item.state] += item.towers.length;
            else stateMap[item.state] = item.towers.length;
            towerCount += item.towers.length;
        });

        const states = [...new Set(data.map((item) => item.state))];
        let stateitems = [];
        states.forEach((item) => {
            data.forEach((elm) => {
                if (elm.state === item) {
                    if (cityMap[elm.city]) cityMap[elm.city] += elm.towers.length;
                    else cityMap[elm.city] = elm.towers.length;
                }
            });
            let stateitem = {
                label: item,
                icon: "fa-solid fa-location-dot",
                badge: stateMap[item],
                badgeClassName: "p-badge-success color2-bg",
                items: [],
            };
            let cities = data.filter((elm) => elm.state === item);

            cities.forEach((city) => {
                let cityitem = {
                    label: city.city,
                    icon: "fa-solid fa-city",
                    badge: cityMap[city.city],
                    badgeClassName: "p-badge-success color2-bg",
                    items: [],
                };
                let sites = data.filter((elm) => elm.city === city.city && elm.state === item);
                // console.log('Sites:',sites)
                sites.forEach((site) => {
                    // console.log(site)
                    site.towers.forEach((tower) => {
                        let siteitem = {
                            label: tower.towerid,
                            icon: "fa-solid fa-tower-cell",
                            to: "/towers-menu/" + tower.siteid + "/" + tower.lat + "/" + tower.lng,
                        };
                        cityitem.items.push(siteitem);
                    });
                });
                stateitem.items.push(cityitem);
            });
            stateitems.push(stateitem);
        });
        let dynamicmenu = {
            label: "Digitized Sites - " + towerCount,
            icon: "pi pi-fw pi-home",
            items: stateitems,
        };
        return dynamicmenu;
    };
    const createdynamicsitemenuinprogress = (data) => {
        let stateMap = {};
        let cityMap = {};
        let towerCount = 0;
        data.forEach((item) => {
            if (stateMap[item.state]) stateMap[item.state] += item.towers.length;
            else stateMap[item.state] = item.towers.length;
            towerCount += item.towers.length;
        });
        const states = [...new Set(data.map((item) => item.state))];
        let stateitems = [];
        states.forEach((item) => {
            data.forEach((elm) => {
                if (elm.state === item) {
                    if (cityMap[elm.city]) cityMap[elm.city] += elm.towers.length;
                    else cityMap[elm.city] = elm.towers.length;
                }
            });
            let stateitem = {
                label: item,
                icon: "fa-solid fa-location-dot",
                badge: stateMap[item],
                badgeClassName: "p-badge-success color2-bg",
                items: [],
            };
            // console.log(cityMap);
            let cities = data.filter((elm) => elm.state === item);
            // console.log('Cities:',cities)
            cities.forEach((city) => {
                let cityitem = {
                    label: city.city,
                    icon: "fa-solid fa-city",
                    badge: cityMap[city.city],
                    badgeClassName: "p-badge-success color2-bg",
                    items: [],
                };
                let sites = data.filter((elm) => elm.city === city.city && elm.state === item);
                // console.log('Sites:',sites)
                sites.forEach((site) => {
                    // console.log(site)
                    site.towers.forEach((tower) => {
                        let siteitem = {
                            label: tower.towerid,
                            icon: "fa-solid fa-tower-cell",
                            to: "/towers-progress-menu/" + tower.siteid + "/" + tower.region + "/" + tower.invcode,
                        };
                        cityitem.items.push(siteitem);
                    });
                });
                stateitem.items.push(cityitem);
            });
            stateitems.push(stateitem);
        });
        let dynamicmenu = {
            label: "InProgress Sites - " + towerCount,
            icon: "pi pi-fw pi-home",
            items: stateitems,
        };
        // console.log(dynamicmenu)
        return dynamicmenu;
    };
    const onServiceSelection = async (serviceTypeName, service, singleService) => {        
        let selMenu = service.menu;
        let mainMenu = [];
        if (selMenu) {
            if(Array.isArray(selMenu.menu)){
                for(let i=0;i<selMenu.menu.length;i++){
                    if(selMenu.menu[i].items){
                        selMenu.menu[i].items = selMenu.menu[i].items.filter((m) => {
                            if (m.icon) m.iconurl = process.env.REACT_APP_IMAGE_STATIC_URL + "/" + m.icon;
                            else m.icon = "pi pi-fw pi-circle";
                            if (m.url) {
                                m.to = removeDomainName(m.url);
                            }
                            delete m.url;
                            return m.hasOwnProperty("to");
                        });
                        mainMenu.push(selMenu.menu[i]);
                    }
                }

            }
            else if (selMenu.menu && selMenu.menu.items) {
                selMenu.menu.items = selMenu.menu.items.filter((m) => {
                    if (m.icon) m.iconurl = process.env.REACT_APP_IMAGE_STATIC_URL + "/" + m.icon;
                    else m.icon = "pi pi-fw pi-circle";
                    if (m.url) {
                        m.to = removeDomainName(m.url);
                    }
                    delete m.url;
                    return m.hasOwnProperty("to");
                });
                mainMenu.push(selMenu.menu);
            }
            if (serviceTypeName === "Asset Monitoring") {
                let assetService = new AssetService();
                let newAssets = await assetService.getAssets(service.serviceId, {});
                let assets = newAssets.filter((item) => item.hasOwnProperty("location"));
                const states = [...new Set(assets.map((item) => item.location.state))];
                let stateitems = [];
                states.forEach((st) => {
                    let stateitem = {
                        label: st,
                        icon: "fa-solid fa-location-dot",
                        items: [],
                    };
                    let cities = assets.filter((elm) => elm.location.state === st);
                    let town = [...new Set(cities.map((item) => item.location.city))];
                    town.forEach((ci) => {
                        let cityitem = {
                            label: ci,
                            icon: "fa-solid fa-city",
                            items: [],
                        };
                        let sites = assets.filter((elm) => elm.location.city.toLowerCase() === ci.toLowerCase() && elm.location.state === st);
                        sites.forEach((s) => {
                            let siteitem = {
                                label: s.thingName,
                                icon: "pi pi-fw pi-angle-right",
                                to: "/site-details/" + s.thingName,
                            };
                            cityitem.items.push(siteitem);
                        });
                        stateitem.items.push(cityitem);
                    });
                    stateitems.push(stateitem);
                });
                let dynamicmenu = {
                    label: "ASSET LOCATIONS",
                    icon: "pi pi-fw pi-home",
                    items: stateitems,
                };

                mainMenu.push(dynamicmenu);
            }

            await SetUserServiceDataInLocalStorage(service, dispatch);
            localStorage.setItem("menu", JSON.stringify(mainMenu));

            setSelectedService(serviceTypeName);
            setSelectedServiceID(service.serviceId);
            if (singleService) {
                handleOK(singleService, serviceTypeName);
            }
            // if (mainMenu[0].url) {
            //     history.push(removeDomainName(mainMenu[0].url));
            // } else if (serviceTypeName === "TeamTrac") {
            //     history.push("/employee-summery?");
            // }else history.push("/");
        }
    };

    const handleOK = async (singleService, serviceTypeName) => {
        if ((singleService && serviceTypeName === "Towers") || selService === "Towers") {
            const towerService = new TowerService();
            let data = await towerService.getStateWiseMenu();
            let progressData = await towerService.getStateWiseInProgressMenu();
            const dynamicsmenu = createdynamicsitemenu(data);
            const progressDynamicsMenu = createdynamicsitemenuinprogress(progressData);
            if (data.length || progressData.length) {
                let getMenu = JSON.parse(localStorage.getItem("menu"));
                if (progressData.length) {
                    localStorage.setItem("menu", JSON.stringify([...getMenu, dynamicsmenu, progressDynamicsMenu]));
                }
                localStorage.setItem("menu", JSON.stringify([...getMenu, dynamicsmenu]));
            }
        }

        let menu = JSON.parse(localStorage.getItem("menu"));
        if (menu[0]?.url) {
            history.push(removeDomainName(menu[0].url));
        } else if ((singleService && serviceTypeName === "TeamTrac") || selService === "TeamTrac") {
            history.push("/employee-summery?");
        } else history.push("/");
    };

    const confirmationDialogFooter = (
        <>
            <Button type="button" label="Cancel" icon="pi pi-times" onClick={() => logout()} className="p-button-text" />
            <Button type="button" label="Accept" icon="pi pi-check" onClick={() => updateServiceIdForTerms()} className="p-button-text" autoFocus />
        </>
    );
    const termsAndConditionAlert = () => {
        return (
            <Dialog header="Terms and Conditions of Usage" closable={false} visible={showTerms} style={{ width: "350px" }} modal footer={confirmationDialogFooter}>
                <div className="flex align-items-center" style={{ fontSize: "1.2rem" }}>
                    <span>
                        I accept{" "}
                        <a target="_blank" href="/Nextqore-Terms+of+Use&PrivacyPolicy.pdf">
                            Terms and Conditions of Usage
                        </a>
                        .
                    </span>
                </div>
            </Dialog>
        );
    };

    const updateServiceIdForTerms = async () => {
        let email = localStorage.getItem("email");
        let [serviceError, serviceDetails] = await parseResponse(TenantService.allowTAndCForServices({ serviceIds: serviceIds, email }));
        setShowTerms(false);
        if (serviceError) return;

        if (filterdPermissionsData.length && filterdPermissionsData.length === 1) {
            onServiceSelection(filterdPermissionsData[0].serviceTypeName, filterdPermissionsData[0], true);
        } else {
            setServices(filterdPermissionsData);
        }
    };

    const logout = () => {
        setShowTerms(false);
        localStorage.clear();
        history.push("/login");
    };

    return (
        <div className="pages-body flex flex-column login-img">
            <div className="align-self-center mt-auto mb-auto">
                {termsAndConditionAlert()}
                {services && services.length ? (
                    <>
                        <div className="card" style={{ borderRadius: "0px", padding: "0" }}>
                            <div className="select-service-heading">Select Service</div>
                            <div style={{ maxHeight: "15rem", overflowY: "auto", paddingTop: "1rem", paddingLeft: "1rem" }}>
                                {services.map((s, i) => {
                                    return (
                                        <div className="col-12" key={i} style={{ paddingRight: "5rem", position: "relative" }}>
                                            <div className="field-radiobutton">
                                                <RadioButton inputId={i} name="option" value={s.serviceTypeName} checked={selServiceID === s.serviceId} onChange={(e) => onServiceSelection(e.value, s)} />
                                                <label htmlFor={i}>{localStorage.getItem("isSupport") === "true" ? s.serviceTypeName + (s.service ? " - " + s.service.descriptor : "") : s.serviceTypeName}</label>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="btn_right">
                                <Button label="OK" className="p-button-plain p-button-text mr-2 mb-2" onClick={handleOK} />
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default ServiceList;

