import React from 'react'
import { Reports } from './reports';
const month = ["Sequence check of the transaction number in TRS",
    "For same vehicle number change in exemption category"
    , "Cases where different fastag is available on same vehicle number",
    "Cases where same vehicle is classified under various classes"
    , "In CCH report for each duplicate/Tag malfunction transaction their should be a valid transaction against it",
    "Adhoc remarks captured for exemption transactions",
    "Analysis of Monthly Pass Vehicles stopped crossing toll plaza",
    "Vehicle passed under ETC fastag also have monthly discount pass",
    "Cases where same vehicle is being attended by same TC and is exempted / violated",
    "Cases where violation was done and tolled on any of the plaza on same day",
    'Class wise paid vehicles converted to not paid and vice versa',
    "Vehicles converted from High class to lower class and Vice Versa",
    "Cases where violation was done and tolled on another of the plaza on same day"
]
const queryMap = {

    "Sequence check of the transaction number in TRS": {
        collection: "nq-transNoSeqBreak_monthly",
        payload: {
            filter: {},
            projection: { _id: 0, mvc: 1, plazacode: 1, vehno: 1, date: 1, transno: 1, trans_code: 1, month: 1 }
        },
        field: ["vehno", "transno", "month", "trans_code", "mvc", "plazacode", "date"],
        head: ["Vehicle Number", "Transaction Number", "Month", "Transaction Code", "Vehicle Class", "Plaza ", "Date"]

    },

    "For same vehicle number change in exemption category": {
        collection: "nq-vehnoExemptCatChange_monthly",
        payload: {
            operation: "aggregate",
            aggregate: [
                { $match: {} },
                {
                    $group: {
                        _id: {
                            vehno: "$vehno",
                        },
                        details: { "$push": { 'vehno': "$vehno", "tagid": "$tagid", 'plazacode': "$plazacode", "mvc": "$mvc", "month": "$month", "extemptedcategory": "$extemptedcategory" } }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: "$_id.vehno",
                        details: "$details",
                        firstDocument: { $arrayElemAt: ["$details", 0] },
                        lastDocument: { $arrayElemAt: ["$details", -1] },
                    }
                },
                {
                    $match: {
                        $expr: {
                            $and: [
                                { $ne: ["$firstDocument.extemptedcategory", "$lastDocument.extemptedcategory"] },
                            ]
                        }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: 1,
                        payment: "$firstDocument.extemptedcategory",
                        payment2: "$lastDocument.extemptedcategory",
                        tagid: "$firstDocument.tagid",
                        plazacode: "$firstDocument.plazacode",
                        mvc: "$firstDocument.mvc",
                        month: "$lastDocument.month"
                    }
                },
                { $skip: 0 }, { $limit: 30 }
            ]
        },
        field: ["vehno", "payment", "payment2", "month", "plazacode", "mvc"],
        head: ["Vehicle Number", "Previous Exempted Category", "New Exempted Category", "Month", "Plaza Code", "Vehicle Class"]
    },
    //range 6
    "Cases where different fastag is available on same vehicle number": {
        collection: "nq-sameVehNoDiffTagId_monthly",
        payload: {
            operation: "aggregate",
            aggregate: [
                { $match: {} },
                {
                    $group: {
                        _id: {
                            vehno: "$vehno",
                        },
                        details: { "$push": { 'vehno': "$vehno", "tagid": "$tagid", 'plazacode': "$plazacode", "mvc": "$mvc", "month": "$month" } }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: "$_id.vehno",
                        details: "$details",
                        firstDocument: { $arrayElemAt: ["$details", 0] },
                        lastDocument: { $arrayElemAt: ["$details", -1] },
                    }
                },
                {
                    $match: {
                        $expr: {
                            $and: [
                                { $ne: ["$firstDocument.tagid", "$lastDocument.tagid"] },
                            ]
                        }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: 1,
                        payment: "$firstDocument.extemptedcategory",
                        tagid: "$firstDocument.tagid",
                        tagid1: "$lastDocument.tagid",
                        plazacode: "$firstDocument.plazacode",
                        mvc: "$firstDocument.mvc",
                        firstMonth: "$firstDocument.month",
                        secondMonth: "$lastDocument.month",
                    }
                },
                { $skip: 0 }, { $limit: 30 }

            ]
        },
        field: ["vehno", "firstMonth", "secondMonth", "tagid", "tagid1", "plazacode", "mvc"],
        head: ["Vehicle Number", "Old Month", "New Month", "Old Tag ID", "New Tag Id", "Plaza Code", "Vehicle Class"]
    },
    "Cases where same vehicle is classified under various classes": {
        collection: "nq-vehDiffClass_FinalSettleAmt_monthly",
        payload: {
            operation: "aggregate",
            aggregate: [
                { $match: {} },
                {
                    $group: {
                        _id: {
                            vehno: "$vehno",
                            month: "$month"
                        },
                        count: { "$sum": 1 },
                        details: { "$push": { 'vehno': "$vehno", "tagid": "$tagid", 'plazacode': "$plazacode", "mvc": "$mvc", "month": "$month" } }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: "$_id.vehno",
                        details: "$details",
                        firstDocument: { $arrayElemAt: ["$details", 0] },
                        lastDocument: { $arrayElemAt: ["$details", -1] },
                    }
                },
                {
                    $match: {
                        $expr: {
                            $and: [
                                { $ne: ["$firstDocument.mvc", "$lastDocument.mvc"] },
                            ]
                        }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: 1,
                        tagid:"$firstDocument.tagid",
                        mvc: "$firstDocument.mvc",
                        mvc1: "$lastDocument.mvc",
                        month: "$lastDocument.month"
                    }
                },
                { $skip: 0 }, { $limit: 30 }
            ]
        },
        field: ["vehno", "month", "mvc", "mvc1"],
        head: ["Vehicle Number", "month", "Previous vehicle class", "New Vehicle Class"]
    },

    "Adhoc remarks captured for exemption transactions": {
        collection: "nq-adhocRemarks_monthly",
        payload: {
            filter: {},
            projection: { _id: 0, month: 1, _col0: 1 }
        },
        field: ["month", "_col0"],
        head: ["Month", "Remarks"]
    },
    "In CCH report for each duplicate/Tag malfunction transaction their should be a valid transaction against it": {
        collection: "nq-duplicate_TagMalFunTxn_monthly",
        payload: {
            filter: {},
            projection: { _id: 0, vehno: 1, month: 1, plazacode: 1, tagid: 1, reason: 1, agencytransid: 1, transactiondt: 1 }
        },
        field: ["agencytransid", "month", "transactiondt", "plazacode", "reason"],
        head: ["Agency Transaction Id", "Month", "Time", "plaza", "Reasons"]

    },

    "Analysis of Monthly Pass Vehicles stopped crossing toll plaza": {
        collection: "nq-vehNotCrossing_with_monthlyPass_6monthly",
        payload: {
            filter: {},
            projection: { _id: 0, plazacode: 1, vehno: 1, dt: 1, month: 1 }
        },
        field: ["vehno", "plazacode", "dt", "month"],
        head: ["Vehicle Number", "plaza", "Time", "Month"]

    },
    "Cases where same vehicle is being attended by same TC and is exempted / violated": {
        collection: "nq-vehWithSame_TC_ExemptViolation_daily",
        payload: {
            filter: {},
            projection: { _id: 0, mvc: 1, plazacode: 1, vehno: 1, date: 1, auditby: 1, extemptedcategory: 1, transno: 1 },
            "skip": 0,
            "limit": 30
        },
        field: ["vehno", "auditby", "transno", "extemptedcategory", "mvc", "plazacode", "date"],
        head: ["Vehicle Number", "Audit By", "Transaction Number", "Extemptedcategory", "Type", "Plaza ", "Date"]

    },

    "Cases where violation was done and tolled on any of the plaza on same day": {
        collection: "nq-ViolateToNonViolate_daily",
        payload: {
            operation: "aggregate",
            aggregate: [
                { $match: {} },
                {
                    $group: {
                        _id: {
                            vehno: "$vehno",
                            month: "$month"
                        },
                        details: { $push: "$$ROOT" }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: "$_id.vehno",
                        payment: { $arrayElemAt: ["$details.amountpaid", -1] },
                        oldplaza: { $arrayElemAt: ["$details.plazacode", 0] },
                        newplaza: { $arrayElemAt: ["$details.plazacode", -1] },
                        mvc: { $arrayElemAt: ["$details.mvc", 0] },
                        paymentMethod: { $arrayElemAt: ["$details.paymentmethod", -1] },
                        month: "$_id.month"

                    }
                },
                {
                    $sort: {
                        "vehno": 1,
                    }
                },
                { $skip: 0 }, { $limit: 30 }
            ]
        },
        field: ["vehno", "payment", "oldplaza", "newplaza", "mvc", "paymentMethod"],
        head: ["vehicle number", "Amount paid", "plaza where violation was done", "plaza where vehicle was tolled", "Vehicle Class", "payment method"]
    },

    "Vehicle passed under ETC fastag also have monthly discount pass": {
        collection: "nq-veh_DiscountMonthlyPass_monthly",
        payload: {
            filter: {},
            projection: { _id: 0, mvc: 1, plazacode: 1, vehno: 1, date: 1, settledamount: 1, acceptedamount: 1, month: 1, transactiontype: 1 }
        },
        field: ["vehno", "settledamount", "acceptedamount", "month", "mvc", "plazacode"],
        head: ["Vehicle Number", "Settledamount", "Acceptedamount", "Month", "Vehicle Class", "Plaza "]

    },
    'Class wise paid vehicles converted to not paid and vice versa': {
        collection: "nq-vehPaymentMethodChange_daily",
        payload: {
            operation: "aggregate",
            aggregate: [
                { $match: {} },
                {
                    $group: {
                        _id: {
                            vehno: "$vehno",
                        },
                        details: { "$push": { 'vehno': "$vehno", 'plazacode': "$plazacode", "svc": "$svc", "month": "$month", "paymentmethod": "$paymentmethod", "amountpaid": "$amountpaid", "transno": "$transno" } }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: "$_id.vehno",
                        details: "$details",
                        firstDocument: { $arrayElemAt: ["$details", 0] },
                        lastDocument: { $arrayElemAt: ["$details", -1] },
                    }
                },
                {
                    $match: {
                        $expr: {
                            $and: [
                                { $ne: ["$firstDocument.transno", "$lastDocument.transno"] }
                            ]
                        }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: 1,
                        OldPaymentMethod: "$firstDocument.paymentmethod",
                        NewPaymentMethod: "$lastDocument.paymentmethod",
                        type: "$firstDocument.svc",
                        amount: "$lastDocument.amountpaid",
                        plazacode: "$firstDocument.plazacode",
                        oldtrans: "$firstDocument.transno",
                        newtrans: "$lastDocument.transno",
                        firstMonth: "$firstDocument.month",
                        secondMonth: "$lastDocument.month",
                    }
                }, {
                    $sort: {
                        amount: -1
                    }
                },
                { $skip: 0 }, { $limit: 30 }
            ]
        },
        field: ["vehno", "OldPaymentMethod", "firstMonth", "oldtrans", "newtrans", "NewPaymentMethod", "secondMonth", "type", "amount", "plazacode"],
        head: ["Vehicle Number", "OldPaymentMethod", "Old Month", "old transaction Number", "New Transaction Number", "NewPaymentMethod", "New Month", "Vehicle Class", "Amount Paid", "Plaza"]
    },

    "Vehicles converted from High class to lower class and Vice Versa": {
        collection: "nq-vehClassChange_daily",
        payload: {
            operation: "aggregate",
            aggregate: [
                { $match: {} },
                {
                    $group: {
                        _id: {
                            vehno: "$vehno",
                        },
                        details: { "$push": { 'vehno': "$vehno", 'plazacode': "$plazacode", "mvc": "$mvc", "month": "$month", "transno": "$transno" } }
                    }
                },

                {
                    $project: {
                        _id: 0,
                        vehno: "$_id.vehno",
                        details: "$details",
                        firstDocument: { $arrayElemAt: ["$details", 0] },
                        lastDocument: { $arrayElemAt: ["$details", -1] },
                    }
                },
                {
                    $match: {
                        $expr: {
                            $and: [
                                { $ne: ["$firstDocument.mvc", "$lastDocument.mvc"] },
                            ]
                        }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: 1,
                        oldCategory: "$firstDocument.mvc",
                        newCategory: "$lastDocument.mvc",
                        oldtransno: "$firstDocument.transno",
                        newtransno: "$lastDocument.transno",
                        plazacode: "$firstDocument.plazacode",
                        firstMonth: "$firstDocument.month",
                        secondMonth: "$lastDocument.month",
                    }
                },
                { $skip: 0 }, { $limit: 30 }
            ]
        },
        field: ["vehno", "oldCategory", "firstMonth", "oldtransno", "newCategory", "secondMonth", "newtransno", "plazacode"],
        head: ["Vehicle Number", "Previous Vehicle Class", "Old Month", "Transaction Number", "New Vehicle Class", "New Month", "Transaction Number", "Plaza"]
    },

    "Cases where violation was done and tolled on another of the plaza on same day": {
        collection: "nq-ViolateToNonViolate_daily",
        payload: {
            operation: "aggregate",
            aggregate: [
                { $match: {} },
                {
                    $group: {
                        _id: {
                            vehno: "$vehno",
                            month: "$month"
                        },
                        details: { $push: "$$ROOT" }
                    }
                },
                {
                    $addFields: {
                        plazacodes: "$details.plazacode"
                    }
                },
                {
                    $match: {
                        $expr: {
                            $ne: [{ $min: "$plazacodes" }, { $max: "$plazacodes" }]
                        }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        vehno: "$_id.vehno",
                        payment: { $arrayElemAt: ["$details.amountpaid", -1] },
                        oldplaza: { $arrayElemAt: ["$details.plazacode", 0] },
                        newplaza: { $arrayElemAt: ["$details.plazacode", -1] },
                        mvc: { $arrayElemAt: ["$details.mvc", 0] },
                        date: { $arrayElemAt: ["$details.date", 0] },
                        paymentMethod: { $arrayElemAt: ["$details.paymentmethod", -1] },
                        month: "$_id.month"
                    }
                },
                {
                    $sort: {
                        "vehno": 1,
                    }
                },
                { $skip: 0 }, { $limit: 30 }
            ]
        },
        field: ["vehno", "payment", "oldplaza", "newplaza", "mvc", "date", "paymentMethod"],
        head: ["vehicle number", "Amount paid", "plaza where violation was done", "plaza where vehicle was tolled", "Vehicle Class", "date", "payment method"]
    },
};


export const Monthly = () => {
    return (
        <div><Reports all={month} queryMap={queryMap} type={"Monthly"} background={"bg-blue-700"} /></div>
    )
}



