import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { useSelector } from "react-redux";
import { convertValue } from "../../../utilities/unitConversion";

const AntennaList = ({ tower, type }) => {
    const unit = useSelector((state) => state.changeUnit);
    const [data, setData] = useState([]);
    const getRanges = (arr) => {
        arr.sort((a, b) => a - b);
        let min = arr[0];
        let max = arr[arr.length - 1];
        if (min % 10) min = Math.floor(min / 10) * 10;
        if (max % 10) max = max + (10 - (max % 10));
        return bucket([min, max], 4);
    };
    const bucket = ([min, max], bucketsNumber) => {
        return Array.from({ length: bucketsNumber }).map((el, idx, arr, step = Math.floor((max - min) / bucketsNumber)) => {
            return idx === bucketsNumber - 1 ? [min + idx * step, max] : [min + idx * step, min + (idx + 1) * step];
        });
    };
    useEffect(() => {
        const tennants = [];
        const legs = [];
        let data = [];

        tower.tenants.antennas.forEach((item) => {
            if (!tennants.includes(item.tennantName)) {
                tennants.push(item.tennantName);
            }
            if (!legs.includes(item.towerLeg)) {
                legs.push(item.towerLeg);
            }
        });
        let towerLegs = legs.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        if (type === "leg") {
            legs.forEach((item) => {
                let temp = tower.tenants.antennas.filter((elm) => elm.towerLeg === item);
                data = [...data, ...temp];
            });
        } else {
            let heights = [];
            // console.log("Sample: ", tower.tenants.antennas);
            // let minHeight = Math.min(...tower.tenants.antennas.map((item) => item.heightfromBase));
            // let maxHeight = Math.max(...tower.tenants.antennas.map((item) => item.heightfromBase));
            // console.log("minHeight: ", minHeight);
            // console.log("maxHeight: ", maxHeight);
            tower.tenants.antennas.forEach((item) => {
                let temp = Math.ceil(convertValue(item.heightfromBase, "height", unit, null, true, null));
                if (!heights.includes(temp)) heights.push(temp);
            });
            let heightRanges = getRanges(heights);

            console.log("tennants: ", tennants);
            tennants.forEach((item) => {
                let tempArr = tower.tenants.antennas.filter((elm) => elm.tennantName === item);
                if (tempArr.length > 0) {
                    let minHeight = Math.min(...tempArr.map((item) => item.heightfromBase));
                    let maxHeight = Math.max(...tempArr.map((item) => item.heightfromBase));
                    let mwCount = tempArr.filter((elm) => elm.type === "MW");
                    let rruCount = tempArr.filter((elm) => elm.type === "RRU");
                    let rfCount = tempArr.filter((elm) => elm.type === "RF");
                    let totalWeight = tempArr.reduce((n, { weight }) => n + weight, 0);
                    let legData = {
                        // towerLeg: leg,
                        height: `${convertValue(minHeight, "height", unit)}-${convertValue(maxHeight, "height", unit)}`,
                        mw: mwCount.length,
                        rf: rfCount.length,
                        rru: rruCount.length,
                        weight: totalWeight,
                    };
                    data.push(legData);
                }
            });

            // heightRanges.forEach((item) => {
            //     let tempArr = tower.tenants.antennas.filter((elm) => elm.heightfromBase > item[0] && elm.heightfromBase <= item[1]);

            //     if (tempArr.length > 0) {
            //         let mwCount = tempArr.filter((elm) => elm.type === "MW");
            //         let rruCount = tempArr.filter((elm) => elm.type === "RRU");
            //         let rfCount = tempArr.filter((elm) => elm.type === "RF");
            //         let totalWeight = tempArr.reduce((n, { weight }) => n + weight, 0);
            //         let legData = {
            //             // towerLeg: leg,
            //             height: `${convertValue(item[0], "height", unit)}-${convertValue(item[1], "height", unit)}`,
            //             mw: mwCount.length,
            //             rf: rfCount.length,
            //             rru: rruCount.length,
            //             weight: totalWeight,
            //         };
            //         data.push(legData);
            //     }

            // });

            // tennants.forEach((item) => {
            //     let temp = tower.tenants.antennas.filter((elm) => elm.tennantName === item);
            //     legs.forEach((leg) => {
            //         let legwise = temp.filter((elm) => elm.towerLeg === leg);
            //         let mwCount = legwise.filter((elm) => elm.type === "MW");
            //         let rruCount = legwise.filter((elm) => elm.type === "RRU");
            //         let rfCount = legwise.filter((elm) => elm.type === "RRU");
            //         let totalWeight = legwise.reduce((n, { weight }) => n + weight, 0);
            //         let legData = {
            //             towerLeg: leg,
            //             tennantName: item,
            //             mw: mwCount.length,
            //             rf: rfCount.length,
            //             rru: rruCount.length,
            //             weight: totalWeight,
            //         };
            //         data.push(legData);
            //     });
            // });
        }
        setData(data);
    }, []);

    const tblHeader =
        type === "leg" ? (
            <ColumnGroup>
                <Row>
                    <Column colSpan={3} className="text-0 color1_1-bg"></Column>
                    <Column colSpan={2} className="text-0 color1_1-bg" header="Height From"></Column>
                    <Column className="text-0 color1_1-bg"></Column>
                    <Column colSpan={2} className="text-0 color1_1-bg" header="Azimuth"></Column>
                    <Column className="text-0 color1_1-bg"></Column>
                </Row>
                <Row>
                    <Column className="text-0 color1-bg" header="#"></Column>
                    <Column className="text-0 color1-bg" header="Tower Leg"></Column>
                    <Column className="text-0 color1-bg" header="Tenant"></Column>
                    <Column className="text-0 color1-bg" header="Base"></Column>
                    <Column className="text-0 color1-bg" header="Ground"></Column>
                    <Column className="text-0 color1-bg" header="Antenna Weight"></Column>
                    <Column className="text-0 color1-bg" header="True North"></Column>
                    <Column className="text-0 color1-bg" header="Magnetic"></Column>
                    <Column className="text-0 color1-bg" header="Down Tilt"></Column>
                </Row>
            </ColumnGroup>
        ) : (
            <ColumnGroup>
                {/* <Row>
                    <Column colSpan={6} className="text-0 color1_1-bg"></Column>
                    <Column colSpan={2} className="text-0 color1_1-bg" header="Height From"></Column>
                    <Column className="text-0 color1_1-bg"></Column>
                    <Column colSpan={2} className="text-0 color1_1-bg" header="Azimuth"></Column>
                    <Column className="text-0 color1_1-bg"></Column>
                </Row> */}
                <Row>
                    <Column className="text-0 color1-bg" header="#"></Column>
                    <Column className="text-0 color1-bg" header="Height"></Column>
                    {/* <Column className="text-0 color1-bg" header="Tower Leg"></Column> */}
                    <Column className="text-0 color1-bg" header="MW"></Column>
                    <Column className="text-0 color1-bg" header="RF"></Column>
                    <Column className="text-0 color1-bg" header="RRU"></Column>
                    <Column className="text-0 color1-bg" header="Total Weight"></Column>
                </Row>
            </ColumnGroup>
        );

    const heightBTemplate = (item) => {
        return convertValue(item.heightfromBase, "height", unit);
    };
    const heightGTemplate = (item) => {
        return convertValue(item.heightfromGround, "height", unit);
    };
    const weightTemplate = (item) => {
        return convertValue(item.weight, "weight", unit);
    };

    return (
        <div className="grid">
            <div className="col-12 lg:col-12">
                {type === "leg" ? (
                    <div className="card">
                        <DataTable value={data} rowGroupMode="rowspan" groupRowsBy="towerLeg" headerColumnGroup={tblHeader} responsiveLayout="scroll" showGridlines>
                            <Column headerStyle={{ width: "3em" }} body={(data, options) => options.rowIndex + 1} className="text-xl color2-bg font-bold"></Column>
                            <Column field="towerLeg" className="text-xl color2-bg font-bold"></Column>
                            <Column field="tennantName" className="text-xl color2-bg font-bold"></Column>
                            <Column field="heightfromBase" body={heightBTemplate} className="text-xl color2-bg font-bold"></Column>
                            <Column field="heightfromGround" body={heightGTemplate} className="text-xl color2-bg font-bold"></Column>
                            <Column field="weight" body={weightTemplate} className="text-xl color2-bg font-bold"></Column>
                            <Column field="azimuthTrueNorth" className="text-xl color2-bg font-bold"></Column>
                            <Column field="azimuthMagnetic" className="text-xl color2-bg font-bold"></Column>
                            <Column field="downtilt" className="text-xl color2-bg font-bold"></Column>
                        </DataTable>
                    </div>
                ) : (
                    <div className="card">
                        <DataTable value={data} rowGroupMode="rowspan" groupRowsBy="height" headerColumnGroup={tblHeader} responsiveLayout="scroll" showGridlines>
                            <Column headerStyle={{ width: "3em" }} body={(data, options) => options.rowIndex + 1} className="text-xl color2-bg font-bold"></Column>
                            <Column field="height" className="text-xl color2-bg font-bold"></Column>
                            {/* <Column field="towerLeg" className="text-xl color2-bg font-bold"></Column> */}
                            <Column field="mw" className="text-xl color2-bg font-bold"></Column>
                            <Column field="rf" className="text-xl color2-bg font-bold"></Column>
                            <Column field="rru" className="text-xl color2-bg font-bold"></Column>
                            <Column field="weight" body={weightTemplate} className="text-xl color2-bg font-bold"></Column>
                        </DataTable>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AntennaList;
