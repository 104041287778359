import React, { useEffect, useState, useRef } from "react";
import XMLParser from "react-xml-parser";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { VehicleSrvice } from "../../service/VehicleService";
import { FuelSrvice } from "../../service/FuelService";
import service from "../../service/dbService";
import ConfigService from "../../service/configService";

const CalibrationDataUpload = () => {
    const toast = useRef(null);
    const [things, setThings] = useState([]);
    const [render, setRender] = useState(true);
    const [selectedThing, setSelectedThing] = useState(null);
    const [etlReferenceData, SetEtlReferenceData] = useState(null);
    const [signalType, setSignalType] = useState(null);
    const vehicleService = new VehicleSrvice();
    const fuelservice = new FuelSrvice();
    const { serviceBlock } = JSON.parse(localStorage.getItem("service"));
    const serviceId = localStorage.getItem("serviceId");
    const prepairXmlData = (data, type) => {
        let tempdata = data.children.filter((item) => item.name === "sensor");
        const allData = tempdata[0].children;
        const digitalData = allData[allData.length - 1];
        const analogData = allData.slice(0, allData.length - 1);

        /* ========= Analog Data =======*/
        const analogMainData = {
            refData: [],
            // refDataType: "Calibration",
            refLevel: selectedThing.code,
        };
        const digitalMainData = {
            refData: [],
            // refDataType: "Calibration",
            refLevel: selectedThing.code,
        };
        analogData.forEach((elm) => {
            analogMainData.refData.push({
                code: elm.attributes.code,
                value: elm.value,
            });
        });

        /* =========== Digital Data ============*/
        let before_ = digitalData.value.substring(0, digitalData.value.indexOf("0"));
        let valuString = digitalData.value.slice(before_.length, digitalData.value.length);
        valuString = valuString.replace(/[^a-zA-Z0-9. ]/g, "").trim(" ");
        const vslueStringArr = valuString.split(" V");
        vslueStringArr.pop();

        for (let str of vslueStringArr) {
            let tempArr = str.trim().split(" ");
            digitalMainData.refData.push({
                code: tempArr[0],
                value: tempArr[tempArr.length - 1],
            });
        }

        if (type === "analog") {
            return digitalMainData;
        } else {
            return analogMainData;
        }
    };
    const readXml = (files) => {
        SetEtlReferenceData(null);
        let tmppath = URL.createObjectURL(files[0]);
        fetch(tmppath)
            .then((res) => res.text())
            .then((data) => {
                let xml = new XMLParser().parseFromString(data);
                let getactualData = prepairXmlData(xml, signalType);
                console.log("getactualData: ", getactualData);
                SetEtlReferenceData(getactualData);
            })
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        getThings();
    }, []);

    const getThings = async () => {
        let addedThings = [];
        const etlStagings = await service.getEtlStagings(serviceId);
        etlStagings.forEach((item) => {
            if (item.etlReferenceData.length > 0) {
                item.etlReferenceData.forEach((elm) => {
                    addedThings.push(elm.refLevel);
                });
            }
        });
        if (serviceBlock === "fleet") {
            vehicleService.getFleetsData(serviceId).then((res) => {
                let things = res.map((item) => ({
                    name: item.vehicle.vehicleNumber,
                    code: item.fleetName,
                    alreadyAdded: addedThings.includes(item.fleetName) ? true : false,
                }));
                setThings(things);
            });
        } else {
            fuelservice.getFuelAssetThings(serviceId).then((res) => {
                let things = res.map((item) => ({
                    name: item.thingName,
                    code: item.thingName,
                    alreadyAdded: addedThings.includes(item.thingName) ? true : false,
                }));
                setThings(things);
            });
        }
    };

    useEffect(() => {
        if (selectedThing) {
            service.getSignalType(serviceId, selectedThing.code).then((res) => {
                setSignalType(res[0].installationParameters.outputSignalType);
            });
        }
    }, [selectedThing]);

    const handelUpload = () => {
        let payload = JSON.parse(JSON.stringify(etlReferenceData));
        payload.serviceId = serviceId;
        ConfigService.uploadCalibration(payload)
            .then((res) => {
                if (res.responseCode === 200) toast.current.show({ severity: "success", summary: "Success", detail: "Calibration Uploaded successfully", life: 3000 });
                else toast.current.show({ severity: "error", summary: "Error", detail: "Somthing went wrong", life: 3000 });
                getThings();
                setRender(!render);
                setSignalType(null);
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Somthing went wrong", life: 3000 });
                setRender(!render);
                setSignalType(null);
            });
    };

    const handelThingChange = (val) => {
        setSignalType(null);
        setSelectedThing(val);
    };
    return (
        <div className="grid justify-content-center align-items-center">
            <div className="col-12 md:col-12 lg:col-4">
                <Toast ref={toast} />
                <Card title="Calibration Upload" style={{ marginBottom: "2em" }} key={render}>
                    <div className="field w-full">
                        <label htmlFor="thing" className="block">
                            Select Things
                        </label>
                        <Dropdown className="w-full" filter value={selectedThing} options={things} onChange={(e) => handelThingChange(e.value)} optionLabel="name" placeholder="Select Thing" optionDisabled="alreadyAdded" />
                        {/* <Dropdown className="w-full" filter value={selectedThing} options={things} onChange={(e) => handelThingChange(e.value)} optionLabel="name" placeholder="Select Thing" /> */}
                    </div>
                    <FileUpload mode="basic" maxFileSize={1000000} onSelect={(e) => readXml(e.files)} disabled={signalType ? false : true} />
                    {/* <input type="file" onChange={(e) => readXml(e.target.files)} /> */}
                    <Button label="Submit" className="mt-5" icon="pi pi-check" onClick={() => handelUpload()} disabled={etlReferenceData ? false : true} />
                </Card>
            </div>
        </div>
    );
};

export default CalibrationDataUpload;
