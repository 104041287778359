import React, { useEffect, useState, useRef } from 'react'
import { plazaServices } from '../tollPlaza/plazaReportService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import "./Lcix.scss"

export const LabelPrinting = () => {
    const [data, setData] = useState([]);
    const [modalData, setModaldata] = useState([])
    const [visible, setVisible] = useState(false);
    const [trackingId, setTrackingId] = useState(null);
    const [trackingUrl, setTrackingUrl] = useState(null);
    const [emailCode, setEmailCode] = useState(null);
    const [delPartners, setDelPartners] = useState(null);
    const [delSpecific, setDelSpecific] = useState(null)
    const [search, setSearch] = useState();
    const [customerId, setCustomerId] = useState();
    const anchorRef = useRef(null);
    const [expandedRows, setExpandedRowData] = useState({})
    const serviceId = localStorage.getItem("serviceId")
    const toast = useRef(null);
    const debounceTimer = useRef(null);
    let jwtToken = localStorage.getItem("token")
    const Header = (
        <ColumnGroup className="bg-cyan-500 text-white card monthly-details">
            <Row>
                <Column header="" className="bg-cyan-500 text-white"></Column>
                <Column header="Batch Id" className="bg-cyan-500 text-white"></Column>
                <Column header="Total Orders" className="bg-cyan-500 text-white"></Column>
                <Column header="Printing Partner" className="bg-cyan-500 text-white"></Column>
                <Column header="" className="bg-cyan-500 text-white"></Column>
            </Row>
        </ColumnGroup>
    );

    useEffect(() => {
        const delPayload = {
            filter: {}
        }
        Promise.all([plazaServices.general(serviceId, delPayload, "nq-delivery-partners")]).then((res) => {
            let temp = [];
            res[0].map((item) => {
                let tempObj = {
                    label: item.name,
                    value: item.name
                }
                temp.push(tempObj)
            })
            setDelPartners(temp);  
        })    
    }, [])

    const initReport=()=>{
        let matchObj = {
            reproBatchId: { $exists: true } 
        }
        if (search && search.length) matchObj["reproBatchId"] = search
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: matchObj
                },
                {
                    $group: {
                        _id: "$reproBatchId",
                        num_unique_orders: { $addToSet: "$customerOrderId" },
                        printPartner: { $first: "$printPartner" },
                        parcel: { $first: "$parcelInfo.shippingLabelUrl" },
                        lastStatus:{ $last:"$lastStatus"}
                    }
                },
                {
                    $project: {
                        email_code: "$_id",
                        totalOrder: { $size: "$num_unique_orders" },
                        printPartner: 1,
                        parcel: 1,
                        lastStatus:1
                    }
                }
            ]
        }
        Promise.all([plazaServices.general(serviceId, payload, "nq-order-items")]).then((res) => {
            setData(res[0]);
        })
    }

    useEffect(() => {
        if (!search) initReport()
            else {
                if (debounceTimer.current) {
                    clearTimeout(debounceTimer.current);
                }
                debounceTimer.current = setTimeout(() => {
                    initReport();
                }, 1000);
            }
            return () => {
                if (debounceTimer.current) {
                    clearTimeout(debounceTimer.current);
                }
            }
    }, [search])

    const edidButton = (row) => {
        if(row.lastStatus!=="SentToRepro")return null;
        const show = () => {
            setEmailCode(row.email_code)
            setVisible(true)
        }
        return generalButton2("pi-file-pdf", "Enter Tracking Id and Tracking Url", show, "", "#0C6291", true, "underline")
    }

    const edidButton2 = (row) => {
        if(row.lastStatus!=="SentToRepro")return null;
        const show = () => {
            setCustomerId(row.customer_id)
            setVisible(true)
        }
        return generalButton2("pi-file-pdf", "Enter Tracking Id and Tracking Url", show, "", "#0C6291", true, "underline")
    }

    const labelPrinting = (row) => {
        let getLabel = () => {
            anchorRef.current.click()
        }
        return <>
            {generalButton2("pi-file-pdf", "Get Label", getLabel, "", "#0C6291", true, "underline")}
            <a ref={anchorRef} href={row.labelurl} target="_blank" rel="noopener noreferrer" style={{ display: 'none' }}>
                Anchor Tag
            </a>
        </>
    }

    const updateOperation = async () => {
        let header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwtToken
        };
        let matchObj = {
            trackingId: trackingId,
            trackingUrl: trackingUrl,
            serviceId: serviceId,
            deliveryPartner: delSpecific,
        }
        if (emailCode) matchObj["reproBatchId"] = emailCode;
        else matchObj["customerOrderId"] = customerId;
        if (trackingId && trackingUrl) {
            let res = await axios({
                method: "post",
                url: `${process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL}/aramex-tracking-update`,
                data: matchObj,
                headers: header,
            });
            if (res.data.responseCode === 200){
                try{
                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL}/shipped-walmart-orders`,
                        data: {serviceId: serviceId},
                        headers: header,
                    });
                }catch(error){
                    console.error(error)
                }
                toast.current.show({ severity: 'success', summary: 'Tracking id and url Inserted ', life: 3000 });
                setVisible(false);
            } 
        }
        else toast.current.show({ severity: 'info', summary: 'Fill all fields', life: 3000 });

    }

    const TollSelector = (specific, list, setFunc, placeHolder) => {
        return (
            <div className="p-field col-12 grid align-items-center">
                <label htmlFor="name" className=' m-0 text-xl col-12 lg:col-5'>{placeHolder}:</label>
                <Dropdown

                    value={specific}
                    options={list}
                    onChange={(e) => { setFunc(e.value) }}
                    placeholder={placeHolder}
                    className=" bg-white col-12 lg:col-6"
                    style={{
                        borderRadius: '1.6rem',
                        padding: "0.2rem",
                        border: '2px solid #0C6291',
                        }}
                />
            </div>
        );
    };

    const generalButton2 = (icon, text, Func, bool, bgColour, Text, underline) => {
        let tcol = "text-white";
        let remainStyle = "col-5 text-xl"
        if (Text) {
            bgColour = "#FFFFFF"
            tcol = " text-primary ";
            remainStyle = ""
        }
        return (
            <div className="ml-1 mr-4 grid  mt-2 col-12 flex justify-content-start" >
                <Button className={`${tcol} ${remainStyle} ${underline}`} style={{ backgroundColor: `${bgColour}` }} onClick={() => Func(bool)} label={text}>
                </Button>
            </div>
        );
    };

    const rowExpansionTemplate = (row) => {
        return (
            <div className='col-12'>
                {modalData[row.email_code]?<><h5>Details for {row.email_code}</h5>
                <DataTable value={modalData[row.email_code]??[]} paginator={true} selectionMode='single' onRowClick={(e) => { }} rows={10} responsiveLayout="scroll" >
                    <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Customer_id" field="customer_id"></Column>
                    <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Total Isbn" field="count"></Column>
                    <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Price" field="total_price"></Column>
                    <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Quantity" field="total_qty"></Column>
                    <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="Tracking Id" field="trackingId"></Column>
                    <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="" body={labelPrinting}></Column>
                    <Column headerClassName="bg-blue-700 text-white " bodyClassName="bg-white font-medium  surface-300 bg-gray-200" header="" body={edidButton2}></Column>
                </DataTable></>:null}
            </div>
        )
    }

    const serachBox = (setFunc, placeHolder, label) => {
        return (
            <div className="p-field col-12 grid mt-1 ">
                {label && <label htmlFor="name " className=' m-0 text-xl  col-12 lg:col-5'>{label}:</label>}
                <InputText
                    className="p-calendar flex align-items-center bg-white col-12 lg:col-6"
                    style={{
                        borderRadius: '1.6rem',
                        padding: "1rem",
                        border: '2px solid #0C6291',
                    }}
                    onChange={(e) => {
                        setFunc(e.target.value)
                    }}
                    id="name"
                    placeholder={placeHolder}
                ></InputText>
            </div>
        );
    }

    const onRowExpand = async (event) => {
        setEmailCode(event.data.email_code)
        const payload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        "reproBatchId": event.data.email_code
                    }
                },
                {
                    $group: {
                        _id: {
                            customer_id: "$customerOrderId",
                            labelurl: "$parcelInfo.shippingLabelUrl"
                        },
                        total_qty: { $sum: { $toInt: "$qty" } },
                        total_price: { $sum: "$charge.amount" },
                        count: { $sum: 1 },
                        lastStatus:{ $last:"$lastStatus"},
                        trackingId:{$first:"$indiaShipment.shipmentId"},
                    }
                },
                {
                    $project: {
                        customer_id: "$_id.customer_id",
                        labelurl: "$_id.labelurl",
                        total_qty: 1,
                        total_price: 1,
                        count: 1,
                        lastStatus:1,
                        trackingId:1
                    }
                }
            ]
        }
        let res = await plazaServices.general(serviceId, payload, "nq-order-items");
        setModaldata({...modalData,[event.data.email_code]:res});
    };
    return (
        <div className='col-12 card'>
            <Toast ref={toast} />
            <div className='col-12'>
                <div className='col-8'>{serachBox(setSearch, "Enter Repro Batch Id", null)}</div>
                <DataTable
                    value={data}
                    expandedRows={expandedRows}
                    onRowToggle={(event) => { setExpandedRowData(event.data)}}
                    rowExpansionMode="single"
                    onRowExpand={(e) => { onRowExpand(e) }}
                    dataKey="_id"
                    paginator rows={10}
                    responsiveLayout="scroll"
                    rowExpansionTemplate={rowExpansionTemplate}
                    headerColumnGroup={Header}
                    id="orderTable"
                >
                    <Column expander style={{ width: '3em' }} />
                    <Column field="email_code" header="Email Code" className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column field="totalOrder" header="Total Order" className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column field="printPartner" header="Print Partner" className="bg-white font-medium surface-300 bg-gray-200" />
                    <Column body={edidButton} className="bg-white font-medium surface-300 bg-gray-200" />
                </DataTable>
            </div>
            <div className='col-12'>
                <Dialog header="Enter Tracking Details" visible={visible} modal style={{ width: "40vw" }} id="trackingDataTable" onHide={() => {
                    setVisible(false)
                    setTrackingId(null);
                    setTrackingUrl(null);
                    setDelSpecific(null);
                    setEmailCode(null);
                    setCustomerId(null);
                }}>
                    <div className="col-12 grid ">
                        {TollSelector(delSpecific, delPartners, setDelSpecific, "Select Delivery Partners")}
                        {serachBox(setTrackingId, "Enter Tracking Id", "Tracking Id")}
                        {serachBox(setTrackingUrl, "Enter Tracking url", "Tracking Url")}
                        {generalButton2("pi-refresh", "Submit", updateOperation, "", "#0C6291", false)}
                    </div>
                </Dialog>
            </div>
        </div>
    )
}