import React, { Component } from "react";
import { TowerService } from "../../service/TowerService";
import CityReportComponent from "./CandedReports/CityReportComponent";
import SiteReportComponent from "./CandedReports/SiteReportComponent";
import SiteComponent from "./SiteComponents";
import { Link } from "react-router-dom";

class TowerSummeryComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            towers: null,
            setReport: false,
            cityLevel: false,
            cityData: null,
            position: null,
            siteLevel: false,
            siteData: null,
            isSite: false,
            site: null
        }
        this.towerService = new TowerService();
        this.pageTitle = '';
        this.bradeCrum = [];
    }

    componentDidMount () {
        const { state, condition } = this.props.match.params;

        this.bradeCrum.push({
            label: state,
            type: "state"
        });

        this.towerService.getStateAllTowers(state).then((towers) => {
            switch (condition) {
                case "withrust":
                    this.pageTitle = 'Rust Report';
                    let tower_with_rust = towers.filter(item => item.towerDefects.rustArea.length > 0 || 
                        item.towerDefects.rustedFasteners.length > 0 || 
                        item.siteDefects.rustingMissingStepsOnStairs.length > 0);
        
                    if(tower_with_rust.length > 0) {
                        this.initReport(tower_with_rust)
                    } 
                    break;
            
                default:
                    break;
            }

        })
    }

    initReport = (towers) => {
        let t_city = [];
        towers.forEach((item) => {
            if (!t_city.includes(item.siteDetails.location.city)) {
                t_city.push(item.siteDetails.location.city);
            }
        });

        let t_city_count = [];
        let t_position = [];
        t_city.forEach((item) => {
            let details = [];
            let t_city_length = towers.filter((elm) => elm.siteDetails.location.city === item);

            t_city_length.forEach((tower) => {
                t_position.push({
                    customerSiteid: tower.customerSiteid,
                    pos: {
                        lat: tower.siteDetails.location.latitude,
                        lng: tower.siteDetails.location.longitude,
                    },
                });
            });

            let t_RTT_type_length = towers.filter((elm) => elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === "RTT");
            if (t_RTT_type_length.length > 0) details.push({ type: "RTT", total: t_RTT_type_length.length });

            let t_GBT_type_length = towers.filter((elm) => elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === "GBT");
            if (t_GBT_type_length.length > 0) details.push({ type: "GBT", total: t_GBT_type_length.length });

            let t_RTP_type_length = towers.filter((elm) => elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === "RTP");
            if (t_RTP_type_length.length > 0) details.push({ type: "RTP", total: t_RTP_type_length.length });

            let t_GBM_type_length = towers.filter((elm) => elm.siteDetails.location.city === item && elm.towerDetails.towerConfig.towerType === "GBM");
            if (t_GBM_type_length.length > 0) details.push({ type: "GBM", total: t_GBM_type_length.length });

            t_city_count.push({
                circleName: item,
                totalSite: t_city_length.length,
                details: [...details],
                position: [...t_position],
            });
        });
        this.setState({
            towers: towers,
            cityData: t_city_count,
            position: t_position,
            setReport: true,
            cityLevel: true,
            siteLevel: false,
        });
    };
    onCityClick = (city) => {
        this.bradeCrum.push({label: city, type: "city"});
        let t_city_level = this.state.towers.filter(item => item.siteDetails.location.city === city);
        this.setState({
            setReport: true,
            cityLevel: false,
            siteLevel: true,
            siteData: t_city_level
        });
    }
    onSiteClick = (id) => {
        this.bradeCrum.push({label: id, type: "site"});
        let index = this.state.towers.findIndex(item => item.customerSiteid === id);
        if(index > -1) {
            this.setState({
                setReport: true,
                cityLevel: false,
                siteLevel: false,
                isSite: true,
                site: this.state.towers[index]
            });
        }
    }
    bradeCrumClick = (type, name) => {
        if(type === 'state') {
            this.setState({
                cityLevel: true,
                siteLevel: false,
                isSite: false,
            });
            this.bradeCrum = [];
            this.bradeCrum.push({
                label: name,
                type : type
            });
        }
        if(type === 'city') {
            this.setState({
                cityLevel: false,
                siteLevel: true,
                isSite: false,
            });
            if(!this.state.siteLevel) {
                this.bradeCrum.splice(-1)
            }
            
        }
    }

    render = () => !this.state.setReport & this.state.towers === null ? null : (
        
        <div className="grid">
            <div className="col-12">
                <h3>{this.pageTitle}</h3>
                <nav className="app-breadcrumb p-breadcrumb p-component" aria-label="Breadcrumb">
                    <ul>
                        <li className="p-breadcrumb-home">
                            <span className="p-menuitem-icon pi pi-home"></span>
                        </li>
                        {
                            this.bradeCrum.map(elm => (
                                <li className="" key={elm.type} onClick={this.bradeCrumClick.bind(this, elm.type, elm.label)}>
                                    <Link to="#" className="p-menuitem-link">
                                        <span className="p-menuitem-text">{elm.label}</span>
                                    </Link>
                                </li>
                            ))
                        }
                        
                    </ul>
                </nav>
            </div>
            {
                this.state.cityLevel ? 
                <CityReportComponent 
                    data={this.state.cityData} 
                    position={this.state.position} 
                    onCityClick={this.onCityClick} /> : null
            }
            {
                this.state.siteLevel ? 
                <SiteReportComponent data={this.state.siteData} onSiteClick={this.onSiteClick} /> : null
            }
            {console.log("ts",this.state.site)}
            {
                this.state.isSite ? 
                <SiteComponent data={this.state.site} /> : null
            }
        </div>
        
    )
}

export default TowerSummeryComponent;