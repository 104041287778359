import React, { useEffect } from 'react'
import { Timeline } from 'primereact/timeline';
import "./Lcix.scss"
import moment from 'moment';


let horizontalEvents = [
    {
        status: "Created",
        date: "",
        completed: false
    },
    {
        status: "Acknowledged",
        date: "",
        completed: false
    }, {
        status: "Sent For Print",
        date: "",
        completed: false
    }, {
        status: "Shipped",
        date: "",
        completed: false
    },
    {
        status: "Delivered",
        date: "",
        completed: false
    }
];

let walmartEvents = [
    {
        status: "Created",
        date: "",
        completed: false
    },
    {
        status: "Acknowledged",
        date: "",
        completed: false
    }, {
        status: "Shipped",
        date: "",
        completed: false
    },
    {
        status: "Delivered",
        date: "",
        completed: false
    }
];
const convertTimeLine = (arr=[], type) => {
    let ans = [];
    for (let i = 0; i < arr.length; i++) {
        let ate = moment(arr[i].dt).format('MMM Do YYYY HH:mm');
        let temp = {
            date: ate,
            completed: true,
            status: arr[i].status
        }
        ans.push(temp);
    }
    if (ans[ans.length - 1].status === "CancellationRequested" || ans[ans.length - 1].status === "Cancelled") return ans;
    if (type === "all") {
        let temp = horizontalEvents.slice(ans.length);
        ans = [...ans, ...temp]
    }
    else {
        let temp = walmartEvents.slice(ans.length);
        ans = [...ans, ...temp]
    }
    console.log("ans", ans)
    return ans;

}
const OrderLineDetails = ({ details }) => {
    let date = new Date(details.orderDate);
    date = date.toLocaleDateString();
    horizontalEvents = convertTimeLine(details.statusLog, "all")
    walmartEvents = convertTimeLine(details.marketPlaceStatusLog, "none")
    useEffect(() => {

    }, [])
    const customizedMarker = (item) => {
        return (
            <span className="flex align-items-center justify-content-center text-white border-circle z-1 shadow-2" style={{ backgroundColor: item.completed ? "#26A541" : "#bdbdbd", width: "1.5rem", height: "1.5rem" }} >
                {item.completed && <i className='pi pi-check' style={{}}></i>}
            </span>
        );
    };
    const customizedContent = (item) => {
        return (
            <div className='p-0 '>
                <p className='m-0 mb-1 text-sm lg:font-semibold'>{item.status}</p>
                <p className=''>{item.date}</p>
            </div>
        );
    };

    const getTextContent = (text) => {
        text = text.replace(/&#[0-9]+;/g, '');
        text = text.replace(/[\(\)\/]/g, '').trim();
        return text;
    }
    return (
        <>
        <div className='mb-3'>
            <div className='card flex flex-wrap shadow-4'>
                <div className='lg:col-4 md:col-3 col-12 md:border-right-1 border-300'>
                    <div className="">
                        <h5 className="">Delivery Address</h5>
                        <h6 className="m-0 mb-1">{details?.shippingInfo?.postalAddress?.name ?? 'N/A'}</h6>
                        <p className="line-height-3 mb-2 flex flex-wrap">
                            {details?.shippingInfo?.postalAddress?.address1 ?? 'N/A'},
                            {details?.shippingInfo?.postalAddress?.address2 ?? ''},
                            {details?.shippingInfo?.postalAddress?.city ?? ''},
                            {details?.shippingInfo?.postalAddress?.postalCode ?? ''}.
                            {details?.shippingInfo?.postalAddress?.state ?? ''},
                            {details?.shippingInfo?.postalAddress?.country ?? ''}
                        </p>
                        <div className="">
                            <span className="font-semibold">Phone number: </span>
                            <span>{details?.shippingInfo?.phone ?? 'N/A'}</span>
                        </div>
                    </div>
                </div>
                <div className='lg:col-4 md:col-3 col-12 md:border-right-1 md:border-top-none border-top-1 border-300'>
                    <div className="md:pl-3 line-height-3">
                        <h5 className="">Order Details</h5>
                        <div className="">
                            <span className="font-semibold">Product Name: </span>
                            <span>{getTextContent(details?.productName ?? 'N/A')}</span>
                        </div>
                        <div className="">
                            <span className="font-semibold">Order ID: </span>
                            <span>{details?.purchaseOrderId ?? 'N/A'}</span>
                        </div>
                        <div className="">
                            <span className="font-semibold">Order Date: </span>
                            <span>{date ?? 'N/A'}</span>
                        </div>
                        <div className="">
                            <span className="font-semibold">Ship By: </span>
                            <span>{details?.indiaShipment?.name ?? 'N/A'}</span>
                        </div>
                    </div>
                </div>
                <div className='lg:col-4 md:col-3 col-12 border-top-1 md:border-none border-300'>
                    <div className="md:pl-3 line-height-3">
                        <h5 className="">Price Distribution</h5>
                        <div className="">
                            <span className="font-semibold">Total: </span>
                            <span>{details?.charge?.amount ?? 'N/A'}</span>
                        </div>
                        <div className="">
                            <span className="font-semibold">Shipping Fee: </span>
                            <span>{details?.chargeBreakup?.[0]?.shippingFee?.amount ?? 'N/A'}</span>
                        </div>
                        <div className="">
                            <span className="font-semibold">Taxes: </span>
                            <span>{details?.chargeBreakup?.[0]?.tax?.taxAmount?.amount ?? 'N/A'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='col-12'>
            <div className='card grid shadow-4'>
                <div className="col-12 lg:col-6">
                    <div className='overflow-auto h-18rem condition-true'>
                        <h5 className='text-center'>All Status Changed Timeline</h5>
                        <Timeline 
                            className='overflow-auto custom-timeline lcix_status' 
                            value={horizontalEvents} 
                            layout="vertical" 
                            align="alternate" 
                            content={customizedContent} 
                            marker={customizedMarker} 
                            opposite={<span>&nbsp;</span>} 
                        />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className='overflow-auto h-18rem condition-true'>
                        <h5 className='text-center'>Walmart Status Timeline</h5>
                        <Timeline 
                            className='overflow-auto custom-timeline lcix_status' 
                            value={walmartEvents} 
                            layout="vertical" 
                            align="alternate" 
                            content={customizedContent} 
                            marker={customizedMarker} 
                            opposite={<span>&nbsp;</span>} 
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
    
    )
}

export default OrderLineDetails